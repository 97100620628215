<div class="container-fluid" style="padding: 20px !important">
     <h1 class="mat-dialog-title arabic text-center">هل أنت متأكد من إزالة هذا المنتج من السلة؟</h1>
     <div class="card" *ngIf="product" style="border: none !important">
          <div class="card-body text-right" style="padding: 1.5rem">
               <div class="row" style="align-items: center">
                    <div class="col-xl-2 col-lg-2 col-md-2 col-5">
                         <a [routerLink]="'/product/' + product.id">
                              <img
                                   *ngIf="product.images"
                                   [src]="product.images[0]"
                                   class="center swiper-image"
                                   style="border-radius: 4px !important; width: 100%" />
                         </a>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 col-12">
                         <span class="text-uppercase text-muted arabic brand w-100">
                              {{ product.markaAR | uppercase }}
                         </span>
                         <a [routerLink]="'/product/' + product.id">
                              <p class="text-uppercase mt-1 arabic text-green" style="font-size: 13px; margin-bottom: 0.25rem">
                                   <b>{{ product.nameAR }}</b>
                              </p>
                         </a>
                         <div class="price d-flex flex-row align-items-center" *ngIf="product.discountType == 'noDiscount'">
                              <span class="act-price">
                                   {{ product.price | currency : " TRY " }} x {{ product.count }} =
                                   {{ product.price * product.count | currency : " TRY " }}</span
                              >
                         </div>
                         <div class="price align-items-center" *ngIf="product.discountType == 'discount'">
                              <p style="margin-bottom: 0.25rem">
                                   <small class="dis-price" style="color: #dc3545">
                                        {{ product.price | currency : " TRY " }} x {{ product.count }} =
                                        {{ product.price * product.count | currency : " TRY " }}</small
                                   >
                              </p>
                              <span class="act-price" style="color: #28a745">
                                   {{ product.price - product.discount | currency : " TRY " }} x {{ product.count }} =
                                   {{ (product.price - product.discount) * product.count | currency : " TRY " }}</span
                              >
                         </div>
                         <div class="price align-items-center" *ngIf="product.discountType == 'percent'">
                              <span class="act-price" style="color: #28a745">
                                   {{ product.price - product.price * (product.discount / 100) | currency : " TRY " }} x {{ product.count }} =
                                   {{
                                        (product.price - product.price * (product.discount / 100)) * product.count
                                             | currency
                                                  : " TRY
                                      "
                                   }}</span
                              >
                              <div>
                                   <small class="dis-price" style="color: #dc3545"> {{ product.price * product.count | currency : " TRY " }}</small>
                                   <span> {{ product.discount }}% خصم</span>
                              </div>
                         </div>
                    </div>
               </div>
          </div>
     </div>
     <div class="mat-dialog-actions" style="margin-bottom: 0px !important">
          <div class="container">
               <div class="row arabic" style="color: #fff">
                    <div class="col-md-4 col-6 center">
                         <button class="btn btn-danger btn-block" type="button" (click)="confirm()">نعم</button>
                    </div>
                    <div class="col-md-4 col-6 center">
                         <button class="btn btn-secondary btn-block" type="button" cdkFocusInitial (click)="cancel()">إلغاء</button>
                    </div>
               </div>
          </div>
     </div>
</div>
