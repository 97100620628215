<div style="padding: 20px !important;">
    <h1 class="mat-dialog-title arabic text-center">أضف عنوان الفاتورة</h1>
    <mat-tab-group dynamicHeight mat-align-tabs="center" mat-stretch-tabs>
        <mat-tab label="شخصي">
            <form class="contact-form mt-1" novalidate [formGroup]="individualForm" #fform="ngForm"
                (ngSubmit)="saveIndividualForm()">
                <div class="mat-dialog-content">
                    <div class="row">
                        <div class="col-md-6">
                            <mat-form-field class="arabic w-100" appearance="outline" color="primary">
                                <mat-label>الاسم</mat-label>
                                <input matInput formControlName="name" type="text" placeholder="الاسم" required>
                                <mat-error *ngIf="formErrors.name">
                                    {{ formErrors.name }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field class="arabic w-100" appearance="outline" color="primary">
                                <mat-label>الكنية</mat-label>
                                <input matInput formControlName="lastname" type="text" placeholder="الكنية" required>
                                <mat-error *ngIf="formErrors.lastname">
                                    {{ formErrors.lastname }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field class="arabic w-100" appearance="outline" color="primary">
                                <mat-label>الرقم الوطني</mat-label>
                                <input matInput formControlName="tc" type="text" placeholder="الرقم الوطني">
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field class="arabic w-100" appearance="outline" color="primary">
                                <mat-label>الهاتف</mat-label>
                                <input matInput formControlName="phone" type="tel" placeholder="مثال 90 531 234 56 78"
                                    pattern="[0-9]*" required>
                                <mat-error *ngIf="formErrors.phone" style="font-size: 11px;">
                                    {{ formErrors.phone }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field class="arabic w-100" appearance="outline" color="primary">
                                <mat-label>المدينة</mat-label>
                                <input matInput formControlName="city" type="text" placeholder="مثال اسطنبول" required>
                                <mat-error *ngIf="formErrors.city">
                                    {{ formErrors.city }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field class="arabic w-100" appearance="outline" color="primary">
                                <mat-label>البلد</mat-label>
                                <input matInput formControlName="country" type="text" placeholder="مثال تركيا" required>
                                <mat-error *ngIf="formErrors.country">
                                    {{ formErrors.country }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field class="arabic w-100" appearance="outline" color="primary">
                                <mat-label>لقب العنوان</mat-label>
                                <input matInput formControlName="addressTitle" type="text"
                                    placeholder="مثال عنوان البيت" required>
                                <mat-error *ngIf="formErrors.addressTitle">
                                    {{ formErrors.addressTitle }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-12">
                            <mat-form-field class="arabic w-100" appearance="outline" color="primary">
                                <mat-label>العنوان</mat-label>
                                <textarea matInput formControlName="address" placeholder="العنوان بالتفصيل" rows="2"
                                    required></textarea>
                                <mat-error *ngIf="formErrors.address">
                                    {{ formErrors.address }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <div class="mat-dialog-actions" style="margin-bottom: 0px !important;">
                    <div class="container-fluid">
                        <div class="row arabic" style="color: #fff;">
                            <div class="col-md-6 col-6">
                                <button class="btn btn-success btn-block" type="submit"
                                    [disabled]="individualForm.invalid" cdkFocusInitial>حفظ</button>
                            </div>
                            <div class="col-md-6 col-6">
                                <button class="btn btn-secondary btn-block" type="button"
                                    (click)="onNoClick()">إلفاء</button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </mat-tab>
        <mat-tab label="شركة">
            <form class="contact-form mt-1" novalidate [formGroup]="corporateForm" #fform="ngForm"
                (ngSubmit)="saveCorporateForm()">
                <div class="mat-dialog-content">
                    <div class="row">
                        <div class="col-md-6">
                            <mat-form-field class="arabic w-100" appearance="outline" color="primary">
                                <mat-label>الاسم</mat-label>
                                <input matInput formControlName="name" type="text" placeholder="الاسم" required>
                                <mat-error *ngIf="formErrors.name">
                                    {{ formErrors.name }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field class="arabic w-100" appearance="outline" color="primary">
                                <mat-label>الكنية</mat-label>
                                <input matInput formControlName="lastname" type="text" placeholder="الكنية" required>
                                <mat-error *ngIf="formErrors.lastname">
                                    {{ formErrors.lastname }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field class="arabic w-100" appearance="outline" color="primary">
                                <mat-label>رقم الضريبة</mat-label>
                                <input matInput formControlName="TIN" type="text" placeholder="رقم التعريف الضريبي"
                                    required>
                                <mat-error *ngIf="formErrors.TIN">
                                    {{ formErrors.TIN }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field class="arabic w-100" appearance="outline" color="primary">
                                <mat-label>إدارة الضرائب</mat-label>
                                <input matInput formControlName="taxAdministration" type="text"
                                    placeholder="إدارة الضرائب" required>
                                <mat-error *ngIf="formErrors.taxAdministration">
                                    {{ formErrors.taxAdministration }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-12">
                            <mat-form-field class="arabic w-100" appearance="outline" color="primary">
                                <mat-label>اسم الشركة</mat-label>
                                <input matInput formControlName="companyName" type="text" placeholder="اسم الشركة"
                                    required>
                                <mat-error *ngIf="formErrors.companyName">
                                    {{ formErrors.companyName }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field class="arabic w-100" appearance="outline" color="primary">
                                <mat-label>الهاتف</mat-label>
                                <input matInput formControlName="phone" type="tel" placeholder="مثال 90 531 234 56 78"
                                    pattern="[0-9]*" required>
                                <mat-error *ngIf="formErrors.phone" style="font-size: 11px;">
                                    {{ formErrors.phone }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field class="arabic w-100" appearance="outline" color="primary">
                                <mat-label>المدينة</mat-label>
                                <input matInput formControlName="city" type="text" placeholder="مثال اسطنبول" required>
                                <mat-error *ngIf="formErrors.city">
                                    {{ formErrors.city }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field class="arabic w-100" appearance="outline" color="primary">
                                <mat-label>البلد</mat-label>
                                <input matInput formControlName="country" type="text" placeholder="مثال تركيا" required>
                                <mat-error *ngIf="formErrors.country">
                                    {{ formErrors.country }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field class="arabic w-100" appearance="outline" color="primary">
                                <mat-label>لقب العنوان</mat-label>
                                <input matInput formControlName="addressTitle" type="text"
                                    placeholder="مثال عنوان البيت" required>
                                <mat-error *ngIf="formErrors.addressTitle">
                                    {{ formErrors.addressTitle }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-12">
                            <mat-form-field class="arabic w-100" appearance="outline" color="primary">
                                <mat-label>العنوان</mat-label>
                                <textarea matInput formControlName="address" placeholder="العنوان بالتفصيل" rows="2"
                                    required></textarea>
                                <mat-error *ngIf="formErrors.address">
                                    {{ formErrors.address }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <div class="mat-dialog-actions" style="margin-bottom: 0px !important;">
                    <div class="container-fluid">
                        <div class="row arabic" style="color: #fff;">
                            <div class="col-md-6 col-6">
                                <button class="btn btn-success btn-block" type="submit"
                                    [disabled]="corporateForm.invalid" cdkFocusInitial>حفظ</button>
                            </div>
                            <div class="col-md-6 col-6">
                                <button class="btn btn-secondary btn-block" type="button"
                                    (click)="onNoClick()">إلغاء</button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </mat-tab>
    </mat-tab-group>
</div>