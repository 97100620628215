<mat-sidenav-container class="example-container">
  <mat-sidenav #sidenav mode="Push" opened="false" [fixedInViewport]="true" fixedTopGap="0">
    <span class="arabic center mt-4 mb-4 text-green" style="width: 100%; text-align: center; padding: 0 18px">
      كل التصنيفات
      <a style="margin-right: 50px" (click)="sidenav.close()">
        <i class="fas fa-times"></i>
      </a>
    </span>
    <hr />
    <mat-nav-list>
      <div *ngFor="let category of categories">
        <a
          mat-list-item
          class="arabic text-green"
          style="font-size: 14px"
          [routerLink]="['/products', category.id]"
          (click)="sidenav.close()">
          <img class="img-sidnav" src="{{ category.icon }}" target="_blank" />
          <span class="arabic" style="margin-top: 5px">{{ category.nameAR }}</span>
        </a>
        <hr class="dropdown-divider" />
      </div>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <div
      mdbModal
      #frame="mdbModal"
      class="modal fade bottom modal-scrolling"
      id="frameModalTop"
      tabindex="-1"
      role="dialog"
      aria-labelledby="myModalLabel"
      aria-hidden="true"
      [config]="{ backdrop: true, ignoreBackdropClick: true }"
      style="background: rgba(0, 0, 0, 0.35)">
      <div class="modal-dialog modal-frame modal-bottom modal-notify modal-info" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <div class="row d-flex justify-content-center align-items-center">
              <div class="col-md-8 col-12">
                <p class="text-right arabic" style="margin-bottom: 0px; display: flex">
                  نحن وشركاؤنا نستخدم ملفات الكوكيز لجمع معلومات عنك وعن جهازك وكيفية تفاعلك مع وياك ونقوم باستخدام هذه المعلومات لتحسين
                  خدماتنا ولتزويدك بمحتوى يلبي رغباتك وإعلانات مخصصة لك. لمعرفة المزيد، يرجى قراءة سياسة ملفات الكوكيز الخاصة بنا.
                </p>
              </div>
              <div class="col-md-2">
                <a class="showMore arabic" routerLink="/privacy-policy" style="margin-right: 4px" [queryParams]="{ mode: 'openCookie' }">
                  سياسة ملفات الكوكيز الخاصة بنا
                </a>
              </div>
              <div class="col-md-2 center col-6">
                <a class="btn btn-halloum2 btn-sm btn-block mt-1 arabic" data-dismiss="modal" (click)="frame.hide(); acceptCookies()">
                  حسناً، شكراً
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <router-outlet></router-outlet>
    <app-fab></app-fab>
  </mat-sidenav-content>
</mat-sidenav-container>
