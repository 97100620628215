<app-header></app-header>
<div *ngIf="spinner">
  <mdb-carousel [isControls]="true" class="carousel slide carousel-fade" [animation]="'fade'">
    <mdb-carousel-item>
      <div class="view w-100" *ngIf="privacy">
        <img
          style="height: 300px; object-fit: cover"
          class="d-block w-100 img-sm"
          src="{{ privacy.arabicImage }}"
          width="100%"
          alt="First slide" />
      </div>
    </mdb-carousel-item>
  </mdb-carousel>
  <main class="main">
    <div class="container" *ngIf="privacy">
      <div *ngIf="privacy">
        <mat-accordion *ngIf="isMode">
          <mat-expansion-panel *ngFor="let priv of privacy.arabic; let i = index" [expanded]="selectedPanel === i">
            <mat-expansion-panel-header>
              <mat-panel-title class="mat-panel-title">
                {{ priv.name }}
              </mat-panel-title>
            </mat-expansion-panel-header>
            <span [innerHTML]="priv.description | safeHTML"></span>
          </mat-expansion-panel>
        </mat-accordion>
        <mat-accordion *ngIf="!isMode">
          <mat-expansion-panel *ngFor="let priv of privacy.arabic">
            <mat-expansion-panel-header>
              <mat-panel-title class="mat-panel-title">
                {{ priv.name }}
              </mat-panel-title>
            </mat-expansion-panel-header>
            <span [innerHTML]="priv.description | safeHTML"></span>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
      <div class="card card-body" style="background-color: #fff" *ngIf="!privacy">
        <h2 class="text-center poppins">No Privacy Policy added yet!</h2>
      </div>
      <mat-spinner style="margin-top: 15px" class="center" *ngIf="!privacy"> </mat-spinner>
    </div>
  </main>
</div>
<div *ngIf="!spinner">
  <div class="view w-100 ph-item">
    <div class="ph-col-12">
      <div style="height: 600px" class="ph-picture" width="100%"></div>
    </div>
  </div>
</div>
<app-footer></app-footer>
