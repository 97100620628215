import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from '../services/auth.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {

  constructor(
    public db: AngularFirestore,
    public authServices: AuthService,
    public router: Router,
    public snackBar: MatSnackBar,
    private title: Title,
    private route: ActivatedRoute,
  ) {
    this.title.setTitle("حلوم اوغلو - معاملة الدفع");
  }

  myOrders: any[] = [];
  spinner: boolean = true;
  user: any;
  id: any;
  params: any;

  payment: any;
  errMess: any = {};

  ngOnInit(): void {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0)
    });

    this.route.queryParams.subscribe((params) => {
      this.params = params;
    })

    if (this.params['status'] && this.params['cartId'] && this.params['paymentId'] && this.params['price'] && this.params['paidPrice'] && (this.params['status'] == 'success')) {
      let uid = JSON.parse(localStorage.getItem('user')).uid;
      this.db.firestore.collection("users").doc(uid).get().then((documentSnapshots) => {
        this.user = documentSnapshots.data();
        this.id = documentSnapshots.id;
        this.getPayments();
      });
    }
    else if (this.params['status'] && this.params['errorCode'] && this.params['errorMessage'] && (this.params['status'] == 'failure')) {
      if (this.params['errorMessage'] == 'Payment Failed, Please Try Again...') {
        this.errMess = {
          status: 'فشل',
          errorCode: this.params['errorCode'],
          errorMessage: 'لقد فشلت عملية الدفع ، يرجى المحاولة مرة أخرى ...'
        }
      }
      else if (this.params['errorMessage'] == 'Payment can not be started because initialization is failed') {
        this.errMess = {
          status: 'فشل',
          errorCode: this.params['errorCode'],
          errorMessage: 'لا يمكن بدء عملية الدفع بسبب فشل التهيئة '
        }
      }
      else {
        this.errMess = {
          status: this.params['status'],
          errorCode: this.params['errorCode'],
          errorMessage: this.params['errorMessage']
        }
      }
      this.spinner = false;
    }
    else {
      this.errMess = {
        status: 'Error',
        errorCode: 500,
        errorMessage: 'An Error Occurred'
      }
      this.spinner = false;
    }
  }

  sortDuplicatedItem(array) {
    const convert = (arr) => {
      const res = {};
      arr.forEach((obj) => {
        const key = `${obj.id}`;
        if (!res[key]) {
          res[key] = { ...obj, count: 0 };
        };
        res[key].count += 1;
      });
      return Object.values(res);
    };
    return convert(array);
  }

  async getPayments() {
    await this.db.firestore.collection('payments').doc(this.params['cartId']).get().then(res => {
      if (res.exists == true) {
        this.payment = res.data();
        this.myOrders = this.sortDuplicatedItem(this.payment.itemTransactions);
        this.spinner = false;
      }
      else {
        this.spinner = false;
        this.errMess = {
          status: 'Error',
          errorCode: 500,
          errorMessage: 'An Error Occurred'
        }
      }
    })
  }

  openSnackBar(message, action) {
    this.snackBar.open(message, action, {
      duration: 4000,
      horizontalPosition: 'left',
      direction: 'ltr',
    });
  }

  signOut() {
    this.authServices.SignOut();
  }
}
