import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-branches',
  templateUrl: './branches.component.html',
  styleUrls: ['./branches.component.scss']
})
export class BranchesComponent implements OnInit {

  branches: any[] = [];
  spinner: boolean = true;

  constructor(
    private router: Router,
    private title: Title,
    public db: AngularFirestore) {
    this.title.setTitle("حلوم اوغلو - فروعنا");
  }

  ngOnInit(): void {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0)
    });
    this.getCategories()
  }

  getCategories() {
    var first = this.db.firestore.collection("branches").orderBy("createdAt", "asc");
    first.get().then((documentSnapshots: any) => {
      for (var i in documentSnapshots.docs) {
        this.branches[i] = documentSnapshots.docs[i].data();
        this.branches[i].id = documentSnapshots.docs[i].id;
      }
      this.spinner = false;
    });
  }
}
