import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { AngularFirestore } from "@angular/fire/firestore";

@Component({
  selector: "app-privacy-policy",
  templateUrl: "./privacy-policy.component.html",
  styleUrls: ["./privacy-policy.component.scss"],
})
export class PrivacyPolicyComponent implements OnInit {
  privacy: any;
  privacy1: any;
  isMode: boolean = false;
  selectedPanel = 0;
  spinner: boolean = false;
  itemsTable: string = `
  <div align="right">

<table class="MsoTableGrid" dir="rtl" border="1" cellspacing="0" cellpadding="0" style="border: none;">
 <tbody>
 <tr>
  <td width="208" valign="top" style="width:155.8pt;border:solid windowtext 1.0pt;
  mso-border-alt:solid windowtext .5pt;padding:0in 5.4pt 0in 5.4pt">
  <p class="MsoNormal" align="center" dir="RTL" style="margin-bottom:0in;text-align:
  center;line-height:normal;direction:rtl;unicode-bidi:embed"><span lang="AR-SA" style="font-size:14.0pt;font-family:&quot;Arial&quot;,sans-serif;mso-ascii-theme-font:
  minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi;
  mso-ansi-language:TR">اسم المنتج<o:p></o:p></span></p>
  </td>
  <td width="208" valign="top" style="width:155.85pt;border:solid windowtext 1.0pt;
  border-right:none;mso-border-right-alt:solid windowtext .5pt;mso-border-alt:
  solid windowtext .5pt;padding:0in 5.4pt 0in 5.4pt">
  <p class="MsoNormal" align="center" dir="RTL" style="margin-bottom:0in;text-align:
  center;line-height:normal;direction:rtl;unicode-bidi:embed"><span lang="AR-SA" style="font-size:14.0pt;font-family:&quot;Arial&quot;,sans-serif;mso-ascii-theme-font:
  minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi;
  mso-ansi-language:TR">الكمية<o:p></o:p></span></p>
  </td>
  <td width="208" valign="top" style="width:155.85pt;border:solid windowtext 1.0pt;
  border-right:none;mso-border-right-alt:solid windowtext .5pt;mso-border-alt:
  solid windowtext .5pt;padding:0in 5.4pt 0in 5.4pt">
  <p class="MsoNormal" align="center" dir="RTL" style="margin-bottom:0in;text-align:
  center;line-height:normal;direction:rtl;unicode-bidi:embed"><span lang="AR-SA" style="font-size:14.0pt;font-family:&quot;Arial&quot;,sans-serif;mso-ascii-theme-font:
  minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi;
  mso-ansi-language:TR">المجموع<o:p></o:p></span></p>
  </td>
 </tr>
 <tr>
  <td width="208" valign="top" style="width:155.8pt;border:solid windowtext 1.0pt;
  border-top:none;mso-border-top-alt:solid windowtext .5pt;mso-border-alt:solid windowtext .5pt;
  padding:0in 5.4pt 0in 5.4pt">
  <p class="MsoNormal" dir="RTL" style="margin-bottom: 0in; line-height: normal; direction: rtl; unicode-bidi: embed;"><span lang="AR-SA" style="font-size:14.0pt;font-family:&quot;Arial&quot;,sans-serif;mso-ascii-theme-font:
  minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi;
  mso-ansi-language:TR">&nbsp;</span></p>
  </td>
  <td width="208" valign="top" style="width:155.85pt;border-top:none;border-left:
  solid windowtext 1.0pt;border-bottom:solid windowtext 1.0pt;border-right:
  none;mso-border-top-alt:solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;
  mso-border-alt:solid windowtext .5pt;padding:0in 5.4pt 0in 5.4pt">
  <p class="MsoNormal" dir="RTL" style="margin-bottom: 0in; line-height: normal; direction: rtl; unicode-bidi: embed;"><span lang="AR-SA" style="font-size:14.0pt;font-family:&quot;Arial&quot;,sans-serif;mso-ascii-theme-font:
  minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi;
  mso-ansi-language:TR">&nbsp;</span></p>
  </td>
  <td width="208" valign="top" style="width:155.85pt;border-top:none;border-left:
  solid windowtext 1.0pt;border-bottom:solid windowtext 1.0pt;border-right:
  none;mso-border-top-alt:solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;
  mso-border-alt:solid windowtext .5pt;padding:0in 5.4pt 0in 5.4pt">
  <p class="MsoNormal" dir="RTL" style="margin-bottom: 0in; line-height: normal; direction: rtl; unicode-bidi: embed;"><span lang="AR-SA" style="font-size:14.0pt;font-family:&quot;Arial&quot;,sans-serif;mso-ascii-theme-font:
  minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi;
  mso-ansi-language:TR">&nbsp;</span></p>
  </td>
 </tr>
</tbody></table>

</div>`;
  cartId: string = "";
  shippingCost: string = "";
  orderDate: string = "";
  deliveryDate: string = "";
  total: string = "";
  buyerName: string = "";
  buyerAddress: string = "";
  buyerPhone: string = "";
  buyerEmail: string = "";
  contactName: string = "";
  shippingAddress: string = "";
  shippingPhone: string = "";
  billingContactName: string = "";
  billingAddress: string = "";
  billingPhone: string = "";

  constructor(
    private router: Router,
    public db: AngularFirestore,
    private route: ActivatedRoute,
    private title: Title
  ) {
    this.title.setTitle("حلوم اوغلو - سياسة الخصوصية");
  }

  ngOnInit(): void {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });

    this.db.firestore
      .collection("content")
      .doc("privacyAR")
      .get()
      .then((documentSnapshots: any) => {
        let data = documentSnapshots.data();
        data.arabic[7].description = data.arabic[7].description
          .replace(/{{itemsTable}}/g, this.itemsTable)
          .replace(/{{shippingCost}}/g, this.shippingCost)
          .replace(/{{orderDate}}/g, this.orderDate)
          .replace(/{{deliveryDate}}/g, this.deliveryDate)
          .replace(/{{total}}/g, this.total)
          .replace(/{{buyerName}}/g, this.buyerName)
          .replace(/{{buyerAddress}}/g, this.buyerAddress)
          .replace(/{{buyerPhone}}/g, this.buyerPhone)
          .replace(/{{contactName}}/g, this.contactName)
          .replace(/{{buyerEmail}}/g, this.buyerEmail)
          .replace(/{{shippingAddress}}/g, this.shippingAddress)
          .replace(/{{shippingPhone}}/g, this.shippingPhone)
          .replace(/{{billingContactName}}/g, this.billingContactName)
          .replace(/{{billingAddress}}/g, this.billingAddress)
          .replace(/{{billingPhone}}/g, this.billingPhone);

        data.arabic[8].description = data.arabic[8].description
          .replace(/{{cartId}}/g, this.cartId)
          .replace(/{{itemsTable}}/g, this.itemsTable)
          .replace(/{{shippingCost}}/g, this.shippingCost)
          .replace(/{{orderDate}}/g, this.orderDate)
          .replace(/{{deliveryDate}}/g, this.deliveryDate)
          .replace(/{{total}}/g, this.total)
          .replace(/{{buyerName}}/g, this.buyerName)
          .replace(/{{buyerAddress}}/g, this.buyerAddress)
          .replace(/{{buyerPhone}}/g, this.buyerPhone)
          .replace(/{{contactName}}/g, this.contactName)
          .replace(/{{buyerEmail}}/g, this.buyerEmail)
          .replace(/{{shippingAddress}}/g, this.shippingAddress)
          .replace(/{{shippingPhone}}/g, this.shippingPhone)
          .replace(/{{billingContactName}}/g, this.billingContactName)
          .replace(/{{billingAddress}}/g, this.billingAddress)
          .replace(/{{billingPhone}}/g, this.billingPhone);
        this.afterReplace(data);
      });

    this.db.firestore
      .collection("content")
      .doc("privacyAR1")
      .get()
      .then((documentSnapshots) => {
        this.privacy1 = documentSnapshots.data().arabic;
        for (let i in this.privacy1) {
          this.privacy.arabic.push(this.privacy1[i]);
        }
      });

    this.route.queryParams.subscribe((params) => {
      if (params["openPanel"]) {
        this.isMode = true;
        this.selectedPanel = parseInt(params["openPanel"]) - 1;
      } else {
        this.isMode = false;
      }
    });
    setTimeout(() => {
      this.spinner = true;
    }, 2000);
  }

  afterReplace(data) {
    this.privacy = data;
  }
}
