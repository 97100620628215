<div class="arabic" style="padding: 24px !important;">
    <h1 class="mat-dialog-title arabic text-center">العقود والنماذج</h1>
    <div style="margin-top: 1.5rem;">
        <h5 class="text-right" style="font-size: 15px;">
            نموذج المعلومات الأولية لاتفاقية البيع عن بعد
        </h5>
        <div class="agreement-div">
            <span [innerHTML]="preliminaryInformationForm"></span>
        </div>
    </div>

    <div style="margin-top: 2.5rem">
        <h5 class="text-right" style="font-size: 15px;">
            اتفاقية البيع عن بعد
        </h5>
        <div class="agreement-div">
            <span [innerHTML]="distanceSalesAgreement"></span>
        </div>
    </div>
</div>