<app-header></app-header>
<div *ngIf="user">
  <main class="main">
    <div class="container-fluid">
      <div class="alert alert-danger" role="alert" *ngIf="!emailVerified">
        لم يتم تأكيد حسابك، لقد أرسلنا لك بالفعل رسالة بريد إلكتروني للتأكد من الحساب ، يرجى التحقق من صندوق البريد الإلكتروني الخاص بك.
        <a (click)="SendVerificationMess()" class="alert-link alert-a">انقر هنا </a>لإرسال بريد إلكتروني لتأكيد حسابك مرة أخرى.
      </div>
      <div class="alert alert-success" role="alert" *ngIf="verificationSent">
        لقد أرسلنا إليك رسالة تأكيد الحساب مرة أخرى — تحقق من صندوق بريدك الإلكتروني!
      </div>
      <div class="col-xl-11 col-md-12 center">
        <div class="row mb-4" *ngIf="user">
          <div *ngIf="!mdScreen" class="col-xl-2 col-lg-2 col-md-3 col-12 mt-2">
            <app-navigator-list></app-navigator-list>
            <p class="poppins text-left" style="font-size: 12px; margin-left: 5px; margin-top: 5px">
              آخر تسجيل دخول:
              <span class="ml-auto text-left">{{ userAuth.lastLoginAt | date : "EEEE, MMM d,y" }}</span>
            </p>
            <p class="text-center poppins" style="font-size: 14px" *ngIf="user">
              تاريخ الانضمام:
              <span>{{ user.createdAt | date : "EEEE, MMM d, y" }}</span>
            </p>
            <div class="col-md-12 center mb-4" *ngIf="user">
              <button class="btn btn-danger btn-block shadow poppins" (click)="deleteModal.show()">حذف الحساب</button>
              <div
                mdbModal
                #deleteModal="mdbModal"
                class="modal right fade"
                tabindex="-1"
                role="dialog"
                aria-labelledby="myBasicModalLabel"
                aria-hidden="true">
                <div class="modal-dialog" role="document">
                  <div class="modal-content">
                    <div class="modal-header poppins">
                      <p class="text-center" style="font-weight: 800; margin-top: 1%; width: 100%">هل انت متأكد ؟</p>
                      <button type="button" class="close pull-right" aria-label="Close" (click)="deleteModal.hide()">
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>
                    <div class="modal-body">
                      <p class="text-left poppins">
                        مرحبا
                        <span style="font-weight: bold; font-size: 16px">{{ user.name | uppercase }},</span>
                        مرحباً، بعد حذف حسابك ، سيتم حذف بياناتك من قاعدة البيانات الخاصة بنا باستثناء
                        <b> اسمك والكنية وطلباتك. </b>
                      </p>
                      <div class="col-md-12" style="float: right">
                        <mat-form-field class="example-full-width poppins" appearance="outline" style="width: 100%">
                          <mat-label>Confirm your operation</mat-label>
                          <input placeholder="Write your email to confirm this operation" matInput [(ngModel)]="passcode" type="text" />
                        </mat-form-field>
                      </div>
                    </div>
                    <div class="modal-footer">
                      <div class="col-md-12 center">
                        <div class="row">
                          <div class="col-md-4 col-6 center mb-2">
                            <button
                              *ngIf="user.email != passcode"
                              style="background: #f93154"
                              class="btn btn-danger btn-block relative waves-light disabled"
                              mdbWavesEffect>
                              Yes
                            </button>
                            <button
                              *ngIf="user.email == passcode"
                              style="background: #f93154"
                              class="btn btn-danger btn-block relative waves-light"
                              (click)="deleteAccount(user.image); deleteModal.hide()"
                              mdbWavesEffect>
                              Yes
                            </button>
                          </div>
                          <div class="col-md-4 col-6 center mb-2">
                            <button
                              class="btn btn-secondary btn-block waves-light"
                              aria-label="Close"
                              (click)="deleteModal.hide()"
                              mdbWavesEffect>
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-10 col-lg-10 col-md-9 col-12 mt-2 mb-2">
            <div class="row">
              <!-- <div class="col-lg-4 col-md-12">
                                   <div  style="border: none">
                                        <img
                                             class="img-fluid rounded-circle shadow-lg center"
                                             width="75%"
                                             style="aspect-ratio: 1 / 1; margin-top: -35px; object-fit: cover"
                                             src="{{ downloadURL }} "
                                        />
                                        <p class="text-center mt-4 poppins" style="color: rgba(0, 0, 0, 0.4)">
                                             {{ user.email }}
                                        </p>
                                        <div class="container" *ngIf="file">
                                             <div class="row" *ngIf="percentage | async as pct">
                                                  <div class="container">
                                                       <mat-progress-bar
                                                            mode="determinate"
                                                            [value]="pct"
                                                       ></mat-progress-bar>
                                                       <p style="margin-bottom: 0rem">{{ pct | number }}%</p>
                                                       <div
                                                            class="ldBar label-center"
                                                            style="width: 50%; height: 50%; margin: auto"
                                                            data-value="35"
                                                            data-preset="circle"
                                                       ></div>
                                                  </div>
                                             </div>
                                             <div *ngIf="snapshot | async as snap">
                                                  <div *ngIf="downloadURL as url">
                                                       <p class="text-center poppins" style="color: #00c851 !important">
                                                            Done
                                                       </p>
                                                  </div>
                                             </div>
                                        </div>
                                        <div class="row">
                                             <div class="col-md-6 col-6">
                                                  <label
                                                       for="file-upload"
                                                       class="custom-file-upload poppins"
                                                       placement="bottom"
                                                       mdbTooltip="Upload"
                                                  >
                                                       <i class="fas fa-cloud-upload-alt"></i>
                                                  </label>
                                                  <input
                                                       id="file-upload"
                                                       (change)="onDrop($event.target.files)"
                                                       type="file"
                                                  />
                                             </div>
                                             <div class="col-md-6 col-6">
                                                  <button
                                                       class="btn btn-upload btn-block"
                                                       (click)="saveImage()"
                                                       placement="bottom"
                                                       mdbTooltip="Save"
                                                       [disabled]="!uploaded"
                                                  >
                                                       <i class="far fa-save"></i>
                                                  </button>
                                             </div>
                                        </div>
                                   </div>
                              </div> -->
              <div style="justify-content: flex-end" class="col-12" *ngIf="!mdScreen">
                <div style="border: none">
                  <!-- <div class="card-header card-header-danger poppins shadow">
                                             <h4 class="card-title">Update Your Profile</h4>
                                             <p class="card-category">Complete your information</p>
                                        </div> -->
                  <div class="card-body">
                    <form class="contact-form mt-1" novalidate [formGroup]="profileForm" #fform="ngForm" (ngSubmit)="onSubmit()">
                      <div style="justify-content: flex-end" class="row">
                        <div style="margin-bottom: 20px back-" class="col-2">
                          <img
                            class="img-fluid"
                            width="100%"
                            style="
                              aspect-ratio: 1 / 1;

                              object-fit: cover;

                              border-radius: 5px;
                            "
                            src="{{ downloadURL }} " />
                        </div>

                        <div style="padding-top: 118px" class="col-md-5">
                          <mat-form-field class="poppins" appearance="outline" color="primary">
                            <mat-label>الاسم</mat-label>
                            <input matInput formControlName="name" [(ngModel)]="user.name" type="text" placeholder="الاسم" required />
                            <mat-error *ngIf="formErrors.name">
                              {{ formErrors.name }}
                            </mat-error>
                          </mat-form-field>
                        </div>
                        <div style="padding-top: 118px" class="col-md-5">
                          <mat-form-field class="arabic" appearance="outline" color="primary">
                            <mat-label>الكنية</mat-label>
                            <input
                              matInput
                              formControlName="lastname"
                              [(ngModel)]="user.lastname"
                              type="text"
                              placeholder="الكنية"
                              required />
                            <mat-error *ngIf="formErrors.lastname">
                              {{ formErrors.lastname }}
                            </mat-error>
                          </mat-form-field>
                        </div>
                        <!-- <div class="col-md-6">
                                                <mat-form-field class="poppins" appearance="outline" color="primary">
                                                    <mat-label>Identity No</mat-label>
                                                    <input matInput formControlName="tc" pattern="[0-9]*"
                                                        [(ngModel)]="user.tc" type="text" placeholder="Your Identity No"
                                                        required>
                                                    <mat-error *ngIf="formErrors.tc">
                                                        {{ formErrors.tc }}
                                                    </mat-error>
                                                </mat-form-field>
                                            </div> -->
                        <div class="col-md-4">
                          <mat-form-field class="poppins" appearance="outline" color="primary">
                            <mat-label>الهاتف</mat-label>
                            <input
                              matInput
                              formControlName="phone"
                              [(ngModel)]="user.phone"
                              type="tel"
                              placeholder="Ex. 90 531 234 56 78"
                              pattern="[0-9]*"
                              required />
                            <mat-error *ngIf="formErrors.phone">
                              {{ formErrors.phone }}
                            </mat-error>
                          </mat-form-field>
                        </div>
                        <div class="col-md-4">
                          <mat-form-field class="poppins" appearance="outline" color="primary">
                            <mat-label>تاريخ الميلاد</mat-label>
                            <input matInput formControlName="dateBirth" [(ngModel)]="user.dateBirth" [matDatepicker]="picker" disabled />
                            <mat-datepicker-toggle dir="ltr" matSuffix [for]="picker"> </mat-datepicker-toggle>
                            <mat-datepicker dir="ltr" touchUi #picker disabled="false"> </mat-datepicker>
                          </mat-form-field>
                        </div>
                        <div class="col-md-4">
                          <mat-form-field class="poppins" appearance="outline" color="primary">
                            <mat-label>الجنس</mat-label>
                            <mat-select class="arabic" formControlName="sex" [(ngModel)]="user.sex" required>
                              <mat-option class="poppins" value="male">ذكر</mat-option>
                              <mat-option class="poppins" value="female">انثى</mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                        <!-- <div class="col-md-4">
                                                            <mat-form-field
                                                                 class="poppins"
                                                                 appearance="outline"
                                                                 color="primary"
                                                            >
                                                                 <mat-label>Nationality</mat-label>
                                                                 <input
                                                                      matInput
                                                                      formControlName="nationality"
                                                                      [(ngModel)]="user.nationality"
                                                                      type="text"
                                                                      placeholder="Ex. Turkish"
                                                                 />
                                                                 <mat-error *ngIf="formErrors.nationality">
                                                                      {{ formErrors.nationality }}
                                                                 </mat-error>
                                                            </mat-form-field>
                                                       </div> -->
                        <div class="col-md-6">
                          <mat-form-field class="poppins" appearance="outline" color="primary">
                            <mat-label>العنوان</mat-label>
                            <input
                              matInput
                              formControlName="address"
                              [(ngModel)]="user.address"
                              type="text"
                              placeholder="عنوانك بالتفصيل"
                              required />
                            <mat-error *ngIf="formErrors.address">
                              {{ formErrors.address }}
                            </mat-error>
                          </mat-form-field>
                        </div>
                        <div class="col-6">
                          <mat-form-field class="poppins" appearance="outline" color="primary">
                            <mat-label>الإيميل</mat-label>
                            <input
                              matInput
                              formControlName="email"
                              [(ngModel)]="user.email"
                              type="email"
                              value="{{ user.email }}"
                              disabled />
                            <mat-error *ngIf="formErrors.address">
                              {{ formErrors.address }}
                            </mat-error>
                          </mat-form-field>
                        </div>
                        <div class="col-md-6">
                          <mat-form-field class="arabic" appearance="outline" color="primary">
                            <mat-label>المدينة</mat-label>
                            <input matInput formControlName="city" [(ngModel)]="user.city" type="text" placeholder="المدينة" required />
                            <mat-error *ngIf="formErrors.city">
                              {{ formErrors.city }}
                            </mat-error>
                          </mat-form-field>
                        </div>
                        <div class="col-md-6">
                          <mat-form-field class="poppins" appearance="outline" color="primary">
                            <mat-label>البلد</mat-label>
                            <input matInput formControlName="country" [(ngModel)]="user.country" type="text" placeholder="البلد" required />
                            <mat-error *ngIf="formErrors.country">
                              {{ formErrors.country }}
                            </mat-error>
                          </mat-form-field>
                        </div>
                        <div class="col-md-3 mt-4 mb-4">
                          <button type="submit" routerLink="/" class="btn btn-warning btn-block poppins">إلغاء</button>
                        </div>
                        <div class="col-md-3 mt-4 mb-4">
                          <button
                            type="submit"
                            [disabled]="profileForm.invalid"
                            *ngIf="!submitted"
                            class="btn btn-success btn-block poppins">
                            حفظ التغييرات
                          </button>
                          <button type="button" [disabled]="true" *ngIf="submitted" class="btn btn-success btn-block shadow poppins">
                            جاري حفظ التغييرات
                            <i style="margin-left: 3px" class="fa fa-spinner fa-spin fa-lg"></i>
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="mdScreen" class="col-xl-2 col-lg-2 col-md-3 col-12 mt-2">
            <app-navigator-list></app-navigator-list>
            <p class="poppins text-left" style="font-size: 12px; margin-left: 5px; margin-top: 5px">
              آخر تسجيل دخول:
              <span class="ml-auto text-left">{{ userAuth.lastLoginAt | date : "EEEE, MMM d,y" }}</span>
            </p>
            <p class="text-center poppins" style="font-size: 14px" *ngIf="user">
              تاريخ الانضمام:
              <span>{{ user.createdAt | date : "EEEE, MMM d, y" }}</span>
            </p>
            <div class="col-md-12 center mb-4" *ngIf="user">
              <button class="btn btn-danger btn-block shadow poppins" (click)="deleteModal.show()">حذف الحساب</button>
              <div
                mdbModal
                #deleteModal="mdbModal"
                class="modal right fade"
                tabindex="-1"
                role="dialog"
                aria-labelledby="myBasicModalLabel"
                aria-hidden="true">
                <div class="modal-dialog" role="document">
                  <div class="modal-content">
                    <div class="modal-header poppins">
                      <p class="text-center" style="font-weight: 800; margin-top: 1%; width: 100%">هل انت متأكد ؟</p>
                      <button type="button" class="close pull-right" aria-label="Close" (click)="deleteModal.hide()">
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>
                    <div class="modal-body">
                      <p class="text-left poppins">
                        مرحبا
                        <span style="font-weight: bold; font-size: 16px">{{ user.name | uppercase }},</span>
                        مرحباً، بعد حذف حسابك ، سيتم حذف بياناتك من قاعدة البيانات الخاصة بنا باستثناء
                        <b> اسمك والكنية وطلباتك. </b>
                      </p>
                      <div class="col-md-12" style="float: right">
                        <mat-form-field class="example-full-width poppins" appearance="outline" style="width: 100%">
                          <mat-label>Confirm your operation</mat-label>
                          <input placeholder="Write your email to confirm this operation" matInput [(ngModel)]="passcode" type="text" />
                        </mat-form-field>
                      </div>
                    </div>
                    <div class="modal-footer">
                      <div class="col-md-12 center">
                        <div class="row">
                          <div class="col-md-4 col-6 center mb-2">
                            <button
                              *ngIf="user.email != passcode"
                              style="background: #f93154"
                              class="btn btn-danger btn-block relative waves-light disabled"
                              mdbWavesEffect>
                              Yes
                            </button>
                            <button
                              *ngIf="user.email == passcode"
                              style="background: #f93154"
                              class="btn btn-danger btn-block relative waves-light"
                              (click)="deleteAccount(user.image); deleteModal.hide()"
                              mdbWavesEffect>
                              Yes
                            </button>
                          </div>
                          <div class="col-md-4 col-6 center mb-2">
                            <button
                              class="btn btn-secondary btn-block waves-light"
                              aria-label="Close"
                              (click)="deleteModal.hide()"
                              mdbWavesEffect>
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style="justify-content: flex-end" class="col-12" *ngIf="mdScreen">
            <div style="border: none">
              <div class="card-body">
                <form class="contact-form mt-1" novalidate [formGroup]="profileForm" #fform="ngForm" (ngSubmit)="onSubmit()">
                  <div style="justify-content: flex-end" class="row">
                    <div style="margin-bottom: 20px back-" class="col-12">
                      <img
                        class="img-fluid"
                        width="100%"
                        style="
                          aspect-ratio: 1 / 1;

                          object-fit: cover;

                          border-radius: 5px;
                        "
                        src="{{ downloadURL }} " />
                    </div>

                    <div style="padding-top: 118px" class="col-md-5">
                      <mat-form-field class="poppins" appearance="outline" color="primary">
                        <mat-label>الاسم</mat-label>
                        <input matInput formControlName="name" [(ngModel)]="user.name" type="text" placeholder="الاسم" required />
                        <mat-error *ngIf="formErrors.name">
                          {{ formErrors.name }}
                        </mat-error>
                      </mat-form-field>
                    </div>
                    <div class="col-md-5">
                      <mat-form-field class="arabic" appearance="outline" color="primary">
                        <mat-label>الكنية</mat-label>
                        <input matInput formControlName="lastname" [(ngModel)]="user.lastname" type="text" placeholder="الكنية" required />
                        <mat-error *ngIf="formErrors.lastname">
                          {{ formErrors.lastname }}
                        </mat-error>
                      </mat-form-field>
                    </div>

                    <div class="col-md-4">
                      <mat-form-field class="poppins" appearance="outline" color="primary">
                        <mat-label>الهاتف</mat-label>
                        <input
                          matInput
                          formControlName="phone"
                          [(ngModel)]="user.phone"
                          type="tel"
                          placeholder="Ex. 90 531 234 56 78"
                          pattern="[0-9]*"
                          required />
                        <mat-error *ngIf="formErrors.phone">
                          {{ formErrors.phone }}
                        </mat-error>
                      </mat-form-field>
                    </div>
                    <div class="col-md-4">
                      <mat-form-field class="poppins" appearance="outline" color="primary">
                        <mat-label>تاريخ الميلاد</mat-label>
                        <input matInput formControlName="dateBirth" [(ngModel)]="user.dateBirth" [matDatepicker]="picker" disabled />
                        <mat-datepicker-toggle dir="ltr" matSuffix [for]="picker"> </mat-datepicker-toggle>
                        <mat-datepicker dir="ltr" touchUi #picker disabled="false"> </mat-datepicker>
                      </mat-form-field>
                    </div>
                    <div class="col-md-4">
                      <mat-form-field class="poppins" appearance="outline" color="primary">
                        <mat-label>الجنس</mat-label>
                        <mat-select class="arabic" formControlName="sex" [(ngModel)]="user.sex" required>
                          <mat-option class="poppins" value="male">ذكر</mat-option>
                          <mat-option class="poppins" value="female">انثى</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>

                    <div class="col-md-6">
                      <mat-form-field class="poppins" appearance="outline" color="primary">
                        <mat-label>العنوان</mat-label>
                        <input
                          matInput
                          formControlName="address"
                          [(ngModel)]="user.address"
                          type="text"
                          placeholder="عنوانك بالتفصيل"
                          required />
                        <mat-error *ngIf="formErrors.address">
                          {{ formErrors.address }}
                        </mat-error>
                      </mat-form-field>
                    </div>
                    <div class="col-md-12">
                      <mat-form-field class="poppins" appearance="outline" color="primary">
                        <mat-label>الإيميل</mat-label>
                        <input matInput formControlName="email" [(ngModel)]="user.email" type="email" value="{{ user.email }}" disabled />
                        <mat-error *ngIf="formErrors.address">
                          {{ formErrors.address }}
                        </mat-error>
                      </mat-form-field>
                    </div>
                    <div class="col-md-6">
                      <mat-form-field class="arabic" appearance="outline" color="primary">
                        <mat-label>المدينة</mat-label>
                        <input matInput formControlName="city" [(ngModel)]="user.city" type="text" placeholder="المدينة" required />
                        <mat-error *ngIf="formErrors.city">
                          {{ formErrors.city }}
                        </mat-error>
                      </mat-form-field>
                    </div>
                    <div class="col-md-6">
                      <mat-form-field class="poppins" appearance="outline" color="primary">
                        <mat-label>البلد</mat-label>
                        <input matInput formControlName="country" [(ngModel)]="user.country" type="text" placeholder="البلد" required />
                        <mat-error *ngIf="formErrors.country">
                          {{ formErrors.country }}
                        </mat-error>
                      </mat-form-field>
                    </div>
                    <div class="col-md-3 mt-4 mb-4">
                      <button type="submit" routerLink="/" class="btn btn-warning btn-block poppins">إلغاء</button>
                    </div>
                    <div class="col-md-3 mt-4 mb-4">
                      <button type="submit" [disabled]="profileForm.invalid" *ngIf="!submitted" class="btn btn-success btn-block poppins">
                        حفظ التغييرات
                      </button>
                      <button type="button" [disabled]="true" *ngIf="submitted" class="btn btn-success btn-block shadow poppins">
                        جاري حفظ التغييرات
                        <i style="margin-left: 3px" class="fa fa-spinner fa-spin fa-lg"></i>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div class="col-md-6 center mt-4 mb-2" *ngIf="errMess">
        <p class="text-center" style="font-size: 3rem">
          <i class="fas fa-times-circle" style="color: #f93154"></i>
        </p>
        <p class="poppins text-center" style="color: #f93154; font-weight: 900">
          {{ errMess }}
        </p>
      </div>

      <!-- <div class="col-lg-4 col-md-12">
               <div class="card card-body shadow" style="border: none">
                    <img
                         class="img-fluid rounded-circle shadow-lg center"
                         width="75%"
                         style="aspect-ratio: 1 / 1; margin-top: -35px; object-fit: cover"
                         src="{{ downloadURL }} "
                    />
                    <p class="text-center mt-4 poppins" style="color: rgba(0, 0, 0, 0.4)">
                         {{ user.email }}
                    </p>
                    <div class="container" *ngIf="file">
                         <div class="row" *ngIf="percentage | async as pct">
                              <div class="container">
                                   <mat-progress-bar mode="determinate" [value]="pct"></mat-progress-bar>
                                   <p style="margin-bottom: 0rem">{{ pct | number }}%</p>
                                   <div
                                        class="ldBar label-center"
                                        style="width: 50%; height: 50%; margin: auto"
                                        data-value="35"
                                        data-preset="circle"
                                   ></div>
                              </div>
                         </div>
                         <div *ngIf="snapshot | async as snap">
                              <div *ngIf="downloadURL as url">
                                   <p class="text-center poppins" style="color: #00c851 !important">Done</p>
                              </div>
                         </div>
                    </div>
                    <div class="row">
                         <div class="col-md-6 col-6">
                              <label
                                   for="file-upload"
                                   class="custom-file-upload poppins"
                                   placement="bottom"
                                   mdbTooltip="Upload"
                              >
                                   <i class="fas fa-cloud-upload-alt"></i>
                              </label>
                              <input id="file-upload" (change)="onDrop($event.target.files)" type="file" />
                         </div>
                         <div class="col-md-6 col-6">
                              <button
                                   class="btn btn-upload btn-block"
                                   (click)="saveImage()"
                                   placement="bottom"
                                   mdbTooltip="Save"
                                   [disabled]="!uploaded"
                              >
                                   <i class="far fa-save"></i>
                              </button>
                         </div>
                    </div>
               </div>
          </div> -->
    </div>
  </main>
</div>

<div *ngIf="!user">
  <div class="view w-100 ph-item">
    <div class="ph-col-12">
      <div style="height: 600px" class="ph-picture" width="100%"></div>
    </div>
  </div>
</div>
<app-footer></app-footer>
