import { Component, OnInit, ViewChild, Inject, HostListener } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AngularFireStorage, AngularFireUploadTask } from "@angular/fire/storage";
import { Observable } from "rxjs";
import { finalize, tap } from "rxjs/operators";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { FirebaseService } from "../services/firebase.service";
import { userData } from "../shared/userData";
import { MatSnackBar } from "@angular/material/snack-bar";

import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from "@angular/material-moment-adapter";
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from "@angular/material/core";
import { AuthService } from "../services/auth.service";
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFirestore } from "@angular/fire/firestore";
import { Title } from "@angular/platform-browser";

export const MY_FORMATS = {
  parse: {
    dateInput: "D/MM/YYYY",
  },
  display: {
    dateInput: "DD/MM/YYYY",
    monthYearLabel: "MMMM Y",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM Y",
  },
};

@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.scss"],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: "en" },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class ProfileComponent implements OnInit {
  user: any;
  userAuth: any;
  id: any;
  email: string;
  dateBirth: string;
  oldImage: string = null;
  files: File[] = [];
  file: File;
  task: AngularFireUploadTask;
  percentage: Observable<number>;
  snapshot: Observable<any>;
  downloadURL: string = null;
  uploaded: boolean = false;
  passcode;
  profileForm: FormGroup;
  profile: userData;
  errMess: string = null;
  submitted: boolean = false;
  emailVerified: boolean = false;
  verificationSent: boolean = false;

  mdScreen: boolean = false;

  @ViewChild("fform") profileFormDirective;

  formErrors = {
    name: "",
    lastname: "",
    phone: "",
    address: "",
    city: "",
    country: "",
    nationality: "",
    sex: "",
    dateBirth: "",
    // 'tc': '',
  };

  validationMessages = {
    name: {
      required: "يرجى إدخال الاسم",
    },
    lastname: {
      required: "يرجى إدخال الكنية",
    },
    phone: {
      required: "يرجى إدخال رقم الهاتف",
      pattern: "يجب ان يحتوي رقم الهاتف على ارقام فقط",
    },
    address: {
      required: "يرجى إدخال الاسم",
    },
    city: {
      required: "يرجى إدخال المدينة",
    },
    country: {
      required: "يرجى إدخال البلد",
    },
    // 'tc': {
    //   'required': 'يرجى إدخال الرقم الوطني',
    //   'pattern': 'يجب ان يحتوي الرقم الوطني على ارقام فقط'
    // }
  };

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    if (event.target.innerWidth <= 850) {
      this.mdScreen = true;
    } else {
      this.mdScreen = false;
    }
  }

  constructor(
    private fb: FormBuilder,
    public afAuth: AngularFireAuth,
    private firebaseService: FirebaseService,
    public snackBar: MatSnackBar,
    public router: Router,
    public db: AngularFirestore,
    private storage: AngularFireStorage,
    public authServices: AuthService,
    private route: ActivatedRoute,
    @Inject(DOCUMENT) private _document: Document,
    private title: Title
  ) {
    this.title.setTitle("حلوم اوغلو - البروفايل");
    if (window.innerWidth <= 850) {
      this.mdScreen = true;
    } else {
      this.mdScreen = false;
    }
  }

  ngOnInit(): void {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });

    this.userAuth = JSON.parse(localStorage.getItem("user"));
    this.emailVerified = this.userAuth.emailVerified;
    this.db.firestore
      .collection("users")
      .doc(this.userAuth.uid)
      .get()
      .then((documentSnapshots: any) => {
        this.user = documentSnapshots.data();
        this.downloadURL = this.user.image ?? "assets/male-user.png";
        this.id = this.user.uid;
      });
    this.createForm();
  }

  createForm() {
    this.profileForm = this.fb.group({
      name: ["", [Validators.required]],
      lastname: ["", [Validators.required]],
      phone: ["", [Validators.required, Validators.pattern]],
      sex: ["", [Validators.pattern]],
      dateBirth: [null],
      address: ["", [Validators.required]],
      city: ["", [Validators.required]],
      country: ["", [Validators.required]],
      email: [{ value: "", disabled: true }, [Validators.required, Validators.email]],
      // tc: ['11111111111', [Validators.required]],
      nationality: [null],
    });

    this.profileForm.valueChanges.subscribe((data) => this.onValueChanged(data));

    this.onValueChanged(); //(re)set form validation messages
  }

  onValueChanged(data?: any) {
    if (!this.profileForm) {
      return;
    }
    const form = this.profileForm;
    for (const field in this.formErrors) {
      if (this.formErrors.hasOwnProperty(field)) {
        // clear previous erroe message (if any)
        this.formErrors[field] = "";
        const control = form.get(field);
        if (control && control.dirty && !control.valid) {
          const messages = this.validationMessages[field];
          for (const key in control.errors) {
            if (control.errors.hasOwnProperty(key)) {
              this.formErrors[field] += messages[key] + " ";
            }
          }
        }
      }
    }
  }

  onDrop(files: FileList) {
    for (let i = 0; i < files.length; i++) {
      this.files.push(files.item(i));
      this.file = this.files[i];
      this.startUpload(this.file);
    }
  }

  startUpload(file) {
    this.oldImage = this.downloadURL;
    const path = `profiles/${Date.now()}_${file.name}`;
    const ref = this.storage.ref(path);
    this.task = this.storage.upload(path, file);
    this.percentage = this.task.percentageChanges();
    this.snapshot = this.task.snapshotChanges().pipe(
      tap(console.log),
      finalize(async () => {
        this.downloadURL = await ref.getDownloadURL().toPromise();
        this.uploaded = true;
        this.files = [];
      })
    );
  }

  saveImage() {
    const formData = {
      image: this.downloadURL,
    };
    this.firebaseService.update_user("users", this.id, formData).then((res) => {
      if (this.oldImage) {
        var userData = JSON.parse(localStorage.getItem("userData"));
        userData.image = this.downloadURL;
        localStorage.setItem("userData", JSON.stringify(userData));
        if (this.oldImage.substring(0, 72) == "https://firebasestorage.googleapis.com/v0/b/halloum-database.appspot.com") {
          this.deleteImage(this.oldImage);
        }
      }
      this.openSnackBar("تم تحديث معلوماتك بنجاح", "✅");
      setTimeout(() => {
        this.refreshPage();
      }, 2000);
    });
  }

  onSubmit() {
    this.submitted = true;
    let date = new Date().toISOString();
    let dateBirth = this.profileForm.value.dateBirth;
    if (dateBirth == null || dateBirth == "") {
      dateBirth = null;
    } else {
      dateBirth = new Date(this.profileForm.value.dateBirth).toISOString();
    }
    if (
      this.profileForm.value.nationality == null ||
      this.profileForm.value.nationality == undefined ||
      this.profileForm.value.nationality == ""
    ) {
      this.profileForm.value.nationality = null;
    }
    const formData = {
      ...this.profileForm.value,
      image: this.downloadURL,
      dateBirth: dateBirth,
      updatedAt: date,
    };
    this.profile = formData;
    this.firebaseService.update_user("users", this.id, this.profile).then((res) => {
      if (this.oldImage) {
        var userData = JSON.parse(localStorage.getItem("userData"));
        userData.image = this.downloadURL;
        localStorage.setItem("userData", JSON.stringify(userData));
        if (this.oldImage.substring(0, 72) == "https://firebasestorage.googleapis.com/v0/b/halloum-database.appspot.com") {
          this.deleteImage(this.oldImage);
        }
      }
      this.openSnackBar("تم تحديث معلوماتك بنجاح", "✅");
      setTimeout(() => {
        let returnUrl = this.route.snapshot.queryParams["returnUrl"] || "/";
        if (returnUrl) {
          this.router.navigateByUrl(returnUrl);
        } else {
          this.refreshPage();
        }
      }, 1500);
    });
  }

  refreshPage() {
    this._document.defaultView.location.reload();
  }

  deleteImage(image) {
    return this.storage.storage.refFromURL(image).delete();
  }

  deleteAccount(image) {
    return this.afAuth.currentUser.then((user) => {
      user.delete().then(
        () => {
          if (image) {
            if (image.substring(0, 72) == "https://firebasestorage.googleapis.com/v0/b/halloum-database.appspot.com") {
              setTimeout(() => {
                this.deleteImage(image);
              }, 200);
            }
          }
          setTimeout(() => {
            this.authServices.SignOut();
          }, 1500);
        },
        (error) => {
          if (error.code == "auth/requires-recent-login") {
            this.errMess =
              "هذه العملية حساسة وتتطلب عملية تسجيل دخول حديثة. قم بتسجيل الخروج ومن ثمّ بتسجيل الدخول مرة أخرى قبل إعادة محاولة هذا الطلب.";
          } else {
            this.errMess = error.message;
          }
        }
      );
    });
  }

  openSnackBar(message, action) {
    this.snackBar.open(message, action, {
      duration: 4000,
      horizontalPosition: "right",
      direction: "rtl",
    });
  }

  signOut() {
    this.authServices.SignOut();
  }

  SendVerificationMess() {
    this.authServices.SendVerificationMail().then(() => {
      this.verificationSent = true;
      this.emailVerified = true;
    });
  }
}
