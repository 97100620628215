import { Component, OnInit, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { NavigationEnd, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AngularFirestore } from '@angular/fire/firestore';
import { Title } from '@angular/platform-browser';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-my-orders',
  templateUrl: './my-orders.component.html',
  styleUrls: ['./my-orders.component.scss']
})
export class MyOrdersComponent implements OnInit {

  constructor(
    public db: AngularFirestore,
    public router: Router,
    public snackBar: MatSnackBar,
    public authServices: AuthService,
    private title: Title,
    @Inject(DOCUMENT) private _document: Document,
  ) {
    this.title.setTitle("حلوم اوغلو - طلباتي");
  }

  myOrders: any[] = [];
  user: any;
  id: any;
  cancel: any;
  spinner: boolean = false;
  itemTransactions: any[] = [];
  itemsToRefund: any[] = [];
  disabled: boolean = false;
  countToRefund: number = 0;
  filteredArray: any[] = [];
  emailVerified: boolean = false;
  verificationSent: boolean = false;

  filterCard: any[] = [
    {
      name: 'كل الطلبات',
      selected: true,
    },
    {
      name: 'الطلبات الملغاة',
      selected: false,
    },
    {
      name: 'الطلبات المرتجعة',
      selected: false,
    },
  ]

  ngOnInit(): void {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0)
    });

    let uid = JSON.parse(localStorage.getItem('user')).uid;
    this.emailVerified = JSON.parse(localStorage.getItem('user')).emailVerified;
    this.spinner = true;
    this.db.firestore.collection("users").doc(uid).get().then((documentSnapshots: any) => {
      this.user = documentSnapshots.data();
      this.id = this.user.uid;
      this.selectFilter(0);
    });
  }

  SendVerificationMess() {
    this.authServices.SendVerificationMail().then(() => {
      this.verificationSent = true;
      this.emailVerified = true;
    });
  }

  selectFilter(id) {
    for (let i in this.filterCard) {
      this.filterCard[i].selected = false;
    }
    this.filterCard[id].selected = true;
    if (this.filterCard[id].name == 'كل الطلبات') {
      this.myOrders = [];
      this.spinner = true;
      this.db.firestore.collection("payments").where('uid', '==', this.id).where('status', '==', 'success').orderBy('createdAt', 'desc').get().then(async (res) => {
        for (let i = 0; i < res.docs.length; i++) {
          this.myOrders[i] = res.docs[i].data()
          this.myOrders[i].id = res.docs[i].id;
          this.myOrders[i].itemTransactions = this.sortDuplicatedItem(res.docs[i].data().itemTransactions);
          this.itemTransactions[i] = res.docs[i].data().itemTransactions;
        }
        this.spinner = false;
      });
    }
    else if (this.filterCard[id].name == 'الطلبات الملغاة') {
      this.myOrders = [];
      this.spinner = true;
      this.db.firestore.collection("payments").where('uid', '==', this.id).where('status', '==', 'success').where('orderStatus', '==', 'canceled').orderBy('createdAt', 'desc').get().then(async (res) => {
        for (let i = 0; i < res.docs.length; i++) {
          this.myOrders[i] = res.docs[i].data()
          this.myOrders[i].id = res.docs[i].id;
          this.myOrders[i].itemTransactions = this.sortDuplicatedItem(res.docs[i].data().itemTransactions);
          this.itemTransactions[i] = res.docs[i].data().itemTransactions;
        }
        this.spinner = false;
      });
    }
    else if (this.filterCard[id].name == 'الطلبات المرتجعة') {
      this.myOrders = [];
      this.spinner = true;
      this.db.firestore.collection("payments").where('uid', '==', this.id).where('status', '==', 'success').where('orderStatus', '==', 'refunded').orderBy('createdAt', 'desc').get().then(async (res) => {
        for (let i = 0; i < res.docs.length; i++) {
          this.myOrders[i] = res.docs[i].data()
          this.myOrders[i].id = res.docs[i].id;
          this.myOrders[i].itemTransactions = this.sortDuplicatedItem(res.docs[i].data().itemTransactions);
          this.itemTransactions[i] = res.docs[i].data().itemTransactions;
        }
        this.spinner = false;
      });
    }
  }

  difference(deliveryDate) {
    var date1 = new Date();
    var date2 = new Date(deliveryDate);
    date2.setDate(date2.getDate() + 14);
    const date1utc = Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getDate());
    const date2utc = Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate());
    let day = 1000 * 60 * 60 * 24;
    return (date2utc - date1utc) / day
  }

  async cancelOrder(cartId) {
    this.disabled = true;
    let date = (new Date()).toISOString();
    const ref = this.db.firestore.collection('payments').doc(cartId);
    await ref.get().then(async res => {
      if (res.exists) {
        await ref.set({
          requestDate: date,
          orderStatus: 'cancel in progress',
        }, { merge: true })
        await this.db.firestore.collection('notifications').doc('cancel').set({
          type: 'cancel',
          opened: false
        })
        this.openSnackBar("لقد تلقينا طلبك لإلغاء الطلب", '✅', 3000)
        setTimeout(() => {
          this.refreshPage();
        }, 3000);
      } else {
        this.openSnackBar('فشل إلغاء الطلب ، يرجى المحاولة مرة أخرى في وقت لاحق!', '❌', 4000)
        setTimeout(() => {
          this.refreshPage();
        }, 4000);
      }
    });
  }

  async refundOrder(cartId, id, productId, countToRefund) {
    console.log(this.itemTransactions[id]);
    this.disabled = true;
    let date = (new Date()).toISOString();
    const ref = this.db.firestore.collection('payments').doc(cartId);
    await ref.get().then(async res => {
      if (res.exists) {
        let j = 0;
        this.itemTransactions[id].find((element, i) => {
          if (element.id === productId) {
            j++;
            this.itemTransactions[id][i] = {
              ...element,
              requestDate: date,
              productStatus: 'refund in progress',
            }
            if (j == countToRefund) return true;
          }
        })
        await ref.set({
          requestDate: date,
          orderStatus: 'refund in progress',
          itemTransactions: this.itemTransactions[id]
        }, { merge: true })
        await this.db.firestore.collection('notifications').doc('refund').set({
          type: 'refund',
          opened: false
        })
        this.openSnackBar("لقد تلقينا طلبك لإعادة الطلب", '✅', 3000)
        setTimeout(() => {
          this.refreshPage();
        }, 3000);
      } else {
        this.openSnackBar('فشل طلب الإرجاع ، يرجى المحاولة مرة أخرى لاحقًا!', '❌', 4000)
        setTimeout(() => {
          this.refreshPage();
        }, 4000);
      }
    });
  }

  countIncrement(product, incrementValue: number) {
    if (incrementValue == 1) {
      if (product.count > this.countToRefund) {
        this.countToRefund = this.countToRefund + incrementValue;
      }
      else {
        this.openSnackBar('يجب أن يكون عدد المنتجات المرتجعة أقل من ' + product.count, '', 4000)
      }
    }
    else if (incrementValue == -1) {
      if (product.count >= this.countToRefund && this.countToRefund > 1) {
        this.countToRefund = this.countToRefund + incrementValue;
      }
      else {
        this.openSnackBar('يجب أن يكون عدد المنتجات المرتجعة أكبر من 0', '', 4000)
      }
    }
  }

  sortDuplicatedItem(array) {
    const convert = (arr) => {
      const res = {};
      arr.forEach((obj) => {
        const key = `${obj.id}`;
        if (!res[key]) {
          res[key] = { ...obj, count: 1 };
        }
        else if (res[key].productStatus != obj.productStatus) {
          res[key + obj.productStatus] = { ...obj, count: 1 };
        }
        else {
          res[key].count += 1;
        }
      });
      return Object.values(res);
    };
    return convert(array);
  }

  openSnackBar(message, action, time) {
    this.snackBar.open(message, action, {
      duration: time,
      horizontalPosition: 'right',
      direction: 'rtl',
    });
  }

  refreshPage() {
    this._document.defaultView.location.reload();
  }

  click(order, id, refundModal) {
    refundModal.show();
    this.filteredArray = order.itemTransactions.filter((product) => {
      return product.id == id && product.productStatus == 'delivered';
    });
  }

}
