<div style="padding: 24px !important;">
    <h1 class="mat-dialog-title arabic text-center">تعديل العنوان</h1>
    <form class="contact-form mt-1" novalidate [formGroup]="addressForm" #fform="ngForm" (ngSubmit)="onSubmit()">
        <div class="mat-dialog-content">
            <div class="row">
                <div class="col-md-6">
                    <mat-form-field class="arabic w-100" appearance="outline" color="primary">
                        <mat-label>الاسم</mat-label>
                        <input matInput formControlName="name" [(ngModel)]="data.name" type="text" placeholder="الاسم"
                            required>
                        <mat-error *ngIf="formErrors.name">
                            {{ formErrors.name }}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-md-6">
                    <mat-form-field class="arabic w-100" appearance="outline" color="primary">
                        <mat-label>الكنية</mat-label>
                        <input matInput formControlName="lastname" [(ngModel)]="data.lastname" type="text"
                            placeholder="الكنية" required>
                        <mat-error *ngIf="formErrors.lastname">
                            {{ formErrors.lastname }}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-md-6">
                    <mat-form-field class="arabic w-100" appearance="outline" color="primary">
                        <mat-label>رقم الهاتف</mat-label>
                        <input matInput formControlName="phone" [(ngModel)]="data.phone" type="tel"
                            placeholder="Ex. 90 531 234 56 78" pattern="[0-9]*" required>
                        <mat-error *ngIf="formErrors.phone" style="font-size: 11px;">
                            {{ formErrors.phone }}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-md-6">
                    <mat-form-field class="arabic w-100" appearance="outline" color="primary">
                        <mat-label>عنوان العنوان</mat-label>
                        <input matInput formControlName="addressTitle" [(ngModel)]="data.addressTitle" type="text"
                            placeholder="مثال: عنوان المنزل" required>
                        <mat-error *ngIf="formErrors.addressTitle">
                            {{ formErrors.addressTitle }}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-md-6">
                    <mat-form-field class="arabic w-100" appearance="outline" color="primary">
                        <mat-label> المدينة </mat-label>
                        <mat-select class="poppins" formControlName="city" [(ngModel)]="data.city">
                            <mat-option class="poppins text-left" dir="ltr" *ngFor="let city of citites; let i = index"
                                value="{{city['name']}}" (click)="cityChosen(i)">
                                <span style="margin-right: 10px;">{{i+1 | number:'2.0'}}</span> {{city["name"] |
                                titlecase}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-md-6">
                    <mat-form-field class="arabic w-100" appearance="outline" color="primary">
                        <mat-label> المنطقة </mat-label>
                        <mat-select class="arabic" formControlName="district" [(ngModel)]="data.district">
                            <mat-option class="poppins text-left" dir="ltr" *ngFor="let district of districts;"
                                value="{{district}}">
                                {{district | titlecase}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-md-12">
                    <mat-form-field class="arabic w-100" appearance="outline" color="primary">
                        <mat-label>العنوان</mat-label>
                        <textarea matInput formControlName="address" [(ngModel)]="data.address"
                            placeholder="قم بكتابة عنوانك بالتفصيل" rows="2" required></textarea>
                        <mat-error *ngIf="formErrors.address">
                            {{ formErrors.address }}
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="mat-dialog-actions" style="margin-bottom: 0px !important;">
            <div class="container-fluid">
                <div class="row arabic" style="color: #fff;">
                    <div class="col-md-6 col-6">
                        <button class="btn btn-success btn-block" type="submit" [disabled]="addressForm.invalid"
                            cdkFocusInitial>حفظ</button>
                    </div>
                    <div class="col-md-6 col-6">
                        <button class="btn btn-secondary btn-block" type="button" (click)="onNoClick()">إلغاء</button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>