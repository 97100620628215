import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from '../services/auth.service';
import { FormBuilder, FormGroup, Validators, } from '@angular/forms';
import { AngularFirestore } from '@angular/fire/firestore';
import { Title } from '@angular/platform-browser';
import { default as dataCity } from '../shared/cities.json';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FirebaseService } from '../services/firebase.service';


export interface DialogData {
  id: string;
  name: string;
  lastname: string;
  phone: string;
  city: string;
  district: string;
  address: string;
  addressTitle: string;
}

@Component({
  selector: 'app-my-addresses',
  templateUrl: './my-addresses.component.html',
  styleUrls: ['./my-addresses.component.scss']
})
export class MyAddressesComponent implements OnInit {

  constructor(
    public db: AngularFirestore,
    public authServices: AuthService,
    public router: Router,
    public snackBar: MatSnackBar,
    private title: Title,
    public dialog: MatDialog,
    private firebaseService: FirebaseService,
  ) {
    this.title.setTitle("حلوم اوغلو - عناويني");
  }

  addresses: any[] = [];
  showSpinner: boolean = true;
  addressForm: FormGroup;

  id: any;
  user: any;

  emailVerified: boolean = false;
  verificationSent: boolean = false;

  ngOnInit(): void {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0)
    });
    let uid = JSON.parse(localStorage.getItem('user')).uid;
    this.emailVerified = JSON.parse(localStorage.getItem('user')).emailVerified;
    this.db.firestore.collection("users").doc(uid).get().then((documentSnapshots: any) => {
      this.user = documentSnapshots.data();
      this.id = this.user.uid;
    });
    this.firebaseService.get_collection('users/' + uid + '/addresses').subscribe((res) => {
      this.addresses = res;
    })
  }

  SendVerificationMess() {
    this.authServices.SendVerificationMail().then(() => {
      this.verificationSent = true;
      this.emailVerified = true;
    });
  }


  deleteAddress(id) {
    this.db.firestore.collection("users").doc(this.id).collection("addresses").doc(id).delete().then((res) => {
      this.openSnackBar("تم حذف عنوانك بنجاح", '✅')
    })
  }

  openDialogEdit(id) {
    const dialogRef = this.dialog.open(EditAddressDialog, {
      width: '600px',
      data: {
        id: this.addresses[id].payload.doc.id,
        name: this.addresses[id].payload.doc.data().name,
        lastname: this.addresses[id].payload.doc.data().lastname,
        phone: this.addresses[id].payload.doc.data().phone,
        city: this.addresses[id].payload.doc.data().city,
        district: this.addresses[id].payload.doc.data().district,
        address: this.addresses[id].payload.doc.data().address,
        addressTitle: this.addresses[id].payload.doc.data().addressTitle,
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }


  openDialog(): void {
    const dialogRef = this.dialog.open(AddAddressDialog, {
      width: '600px',
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  openSnackBar(message, action) {
    this.snackBar.open(message, action, {
      duration: 4000,
      horizontalPosition: 'right',
      direction: 'rtl',
    });
  }

  signOut() {
    this.authServices.SignOut();
  }
}

@Component({
  selector: 'add-address-dialog',
  templateUrl: 'add-address-dialog.html',
})
export class AddAddressDialog {
  citites: any[] = [];
  districts: any[] = [];
  submitted: boolean = false;
  addressForm: FormGroup;
  cityValue: boolean = false;
  address: any;
  uid: any;

  @ViewChild('fform') addressFormDirective;

  formErrors = {
    'name': '',
    'lastname': '',
    'phone': '',
    'city': '',
    'district': '',
    'address': '',
    'addressTitle': '',
  };

  validationMessages = {
    'name': {
      'required': 'يرجى إدخال الاسم',
    },
    'lastname': {
      'required': 'يرجى إدخال الكنية',
    },
    'phone': {
      'required': 'يرجى إدخال رقم الهاتف',
      'pattern': 'يجب ان يحتوي رقم الهاتف على ارقام فقط'
    },
    'city': {
      'required': 'يرجى إدخال المدينة',
    },
    'district': {
      'required': 'يرجى إدخال المنطقة',
    },
    'address': {
      'required': 'يرجى إدخال العنوان',
    },
    'addressTitle': {
      'required': 'يرجى إدخال عنوان العنوان',
    },
  };

  constructor(
    public dialogRef: MatDialogRef<AddAddressDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private fb: FormBuilder,
    public db: AngularFirestore,
    public snackBar: MatSnackBar,
    private route: ActivatedRoute,
    public router: Router,
  ) {
    this.citites = dataCity;
    this.uid = JSON.parse(localStorage.getItem('user')).uid;
    this.addressForm = this.fb.group({
      name: ['', [Validators.required]],
      lastname: ['', [Validators.required]],
      phone: ['', [Validators.required, Validators.pattern]],
      city: ['', [Validators.required]],
      district: ['', [Validators.required]],
      address: ['', [Validators.required]],
      addressTitle: ['', [Validators.required]],
    });

    this.addressForm.valueChanges
      .subscribe(data => this.onValueChanged(data));

    this.onValueChanged(); //(re)set form validation messages 
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  cityChosen(i) {
    this.cityValue = true;
    this.districts = this.citites[i].counties;
  }

  onValueChanged(data?: any) {
    if (!this.addressForm) {
      return;
    }
    const form = this.addressForm;
    for (const field in this.formErrors) {
      if (this.formErrors.hasOwnProperty(field)) {
        // clear previous erroe message (if any)
        this.formErrors[field] = '';
        const control = form.get(field);
        if (control && control.dirty && !control.valid) {
          const messages = this.validationMessages[field];
          for (const key in control.errors) {
            if (control.errors.hasOwnProperty(key)) {
              this.formErrors[field] += messages[key] + ' ';
            }
          }
        }
      }
    }
  }

  onSubmit() {
    this.submitted = true;
    let date = (new Date()).toISOString();
    const formData = {
      ...this.addressForm.value,
      updatedAt: date
    };
    this.address = formData;

    this.db.firestore.collection("users").doc(this.uid).collection("addresses").add(this.address)
      .then((res) => {
        this.openSnackBar("تم حفظ عنوانك بنجاح", '✅')
        setTimeout(() => {
          let returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
          if (returnUrl) {
            this.router.navigateByUrl(returnUrl);
          }
          this.onNoClick();
        }, 200);
      })
  }


  openSnackBar(message, action) {
    this.snackBar.open(message, action, {
      duration: 4000,
      horizontalPosition: 'right',
      direction: 'rtl',
    });
  }
}

@Component({
  selector: 'edit-address-dialog',
  templateUrl: 'edit-address-dialog.html',
})
export class EditAddressDialog {
  citites: any[] = [];
  districts: any[] = [];
  submitted: boolean = false;
  addressForm: FormGroup;
  cityValue: boolean = false;
  address: any;
  uid: any;

  @ViewChild('fform') addressFormDirective;

  formErrors = {
    'name': '',
    'lastname': '',
    'phone': '',
    'city': '',
    'district': '',
    'address': '',
    'addressTitle': '',
  };

  validationMessages = {
    'name': {
      'required': 'يرجى إدخال الاسم',
    },
    'lastname': {
      'required': 'يرجى إدخال الكنية',
    },
    'phone': {
      'required': 'يرجى إدخال رقم الهاتف',
      'pattern': 'يجب ان يحتوي رقم الهاتف على ارقام فقط'
    },
    'city': {
      'required': 'يرجى إدخال المدينة',
    },
    'district': {
      'required': 'يرجى إدخال المنطقة',
    },
    'address': {
      'required': 'يرجى إدخال العنوان',
    },
    'addressTitle': {
      'required': 'يرجى إدخال عنوان العنوان',
    },
  };

  constructor(
    public dialogRef: MatDialogRef<EditAddressDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private fb: FormBuilder,
    public db: AngularFirestore,
    public snackBar: MatSnackBar,
    private route: ActivatedRoute,
    public router: Router,
  ) {
    this.citites = dataCity;
    const index = this.citites.map(e => e.name).indexOf(data.city);
    this.districts = this.citites[index].counties;
    this.uid = JSON.parse(localStorage.getItem('user')).uid;
    this.addressForm = this.fb.group({
      name: ['', [Validators.required]],
      lastname: ['', [Validators.required]],
      phone: ['', [Validators.required, Validators.pattern]],
      city: ['', [Validators.required]],
      district: ['', [Validators.required]],
      address: ['', [Validators.required]],
      addressTitle: ['', [Validators.required]],
    });

    this.addressForm.valueChanges
      .subscribe(data => this.onValueChanged(data));

    this.onValueChanged(); //(re)set form validation messages 
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  cityChosen(i) {
    this.cityValue = true;
    this.districts = this.citites[i].counties;
  }

  onValueChanged(data?: any) {
    if (!this.addressForm) {
      return;
    }
    const form = this.addressForm;
    for (const field in this.formErrors) {
      if (this.formErrors.hasOwnProperty(field)) {
        // clear previous erroe message (if any)
        this.formErrors[field] = '';
        const control = form.get(field);
        if (control && control.dirty && !control.valid) {
          const messages = this.validationMessages[field];
          for (const key in control.errors) {
            if (control.errors.hasOwnProperty(key)) {
              this.formErrors[field] += messages[key] + ' ';
            }
          }
        }
      }
    }
  }

  onSubmit() {
    this.submitted = true;
    let date = (new Date()).toISOString();
    const formData = {
      ...this.addressForm.value,
      updatedAt: date
    };
    this.address = formData;
    this.db.firestore.collection("users").doc(this.uid).collection("addresses").doc(this.data.id).set({
      ...this.address
    }, { merge: true }).then((res) => {
      this.openSnackBar("تم حفظ عنوانك بنجاح", '✅')
      setTimeout(() => {
        let returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
        if (returnUrl) {
          this.router.navigateByUrl(returnUrl);
        }
        this.onNoClick();
      }, 200);
    })
  }

  openSnackBar(message, action) {
    this.snackBar.open(message, action, {
      duration: 4000,
      horizontalPosition: 'right',
      direction: 'rtl',
    });
  }
}