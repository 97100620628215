<footer *ngIf="!mdScreen" class="page-footer font-small pt-4">
  <div class="container-fluid" style="color: #000">
    <div class="col-xl-3 colrg-3 col-md-4 poppins ml-auto"></div>
  </div>

  <div class="container text-md-left lemonada" style="text-align: right; margin-top: 30px">
    <div class="row">
      <div class="col-md-3">
        <div style="border-left: 2px solid #ffc10e; padding-left: 5px">
          <a routerLink="/">
            <img src="assets/h.png" class="center" style="width: 80%" />
          </a>
        </div>
      </div>

      <div class="col-md-5">
        <div class="row">
          <div class="col-md-6 mb-md-0 mb-3" style="padding-top: 20px; text-align: center">
            <h5 class="text-uppercase text-gold arabic" style="text-align: right; font-size: 13px; margin-top: 30px">شركة</h5>
            <ul class="list-unstyled arabic" style="text-align: right; padding-right: 0px !important">
              <li>
                <a class="" routerLink="/about">عن حلوم</a>
              </li>
              <li>
                <a class="" routerLink="/our-team">فريقنا</a>
              </li>
              <li>
                <a class="" routerLink="/contact">اتصل بنا</a>
              </li>
              <li>
                <a class="" routerLink="/branches">فروعنا</a>
              </li>

              <li>
                <a class="" routerLink="/privacy-policy">سياسة الخصوصية</a>
              </li>
              <li>
                <a class="" routerLink="/membership-agreemant">اتفاقية العضوية</a>
              </li>
            </ul>
          </div>
          <div class="col-md-6 mb-md-0 mb-3">
            <h5 class="text-uppercase text-gold arabic" style="text-align: right; font-size: 13px; margin-top: 30px">استطلع</h5>
            <ul class="list-unstyled arabic" style="text-align: right; padding-right: 0px !important">
              <li>
                <a class="" routerLink="/">الرئيسية</a>
              </li>
              <li>
                <a class="" routerLink="/careers">وظائف </a>
              </li>
              <li>
                <a class="" routerLink="/categories">التصنيفات</a>
              </li>
              <li>
                <a class="" routerLink="/gallery">الاستديو</a>
              </li>

              <li>
                <a class="" routerLink="/loyalty-program">Loyalty Program</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="row">
          <div class="col-12">
            <div style="display: flex; justify-content: center">
              <img src="assets/halal.png" style="width: 7vw" alt="" />
              <img src="assets/organic.png" style="width: 7vw" alt="" />
            </div>
            <div class="footer-social">
              <a href="https://www.facebook.com/Halloumoglu.tr/" target="_blank"><i class="fab fa-facebook-square"></i></a>
              <a href="https://www.instagram.com/halloumoglu.tr/" target="_blank"><i class="fab fa-instagram"></i></a>
              <!-- <a href="https://twitter.com/halloumoglu" target="_blank"><i class="fab fa-twitter"></i></a> -->
              <a
                href="https://wa.me/+905300888034/?text=Welcome%20to%20Halloum%20dairy,%20kindly%20leave%20your%20message%20and%20we%20will%20contact%20you%20back%20as%20soon%20as%20possible."
                ><i class="fab fa-whatsapp" target="_blank"></i
              ></a>
              <!-- <a href="https://t.me/OzgurKenan" target="_blank"><i class="fab fa-telegram"></i></a> -->
              <a href="https://www.youtube.com/c/HALLOUMOGLU" target="_blank"><i class="fab fa-youtube"></i></a>
              <a href="https://vm.tiktok.com/ZSJPNgqPh/" target="_blank"><i class="fab fa-tiktok"></i></a>
              <a href="tel:+905300888034" target="_blank">
                <i class="fas fa-headset"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-5 col-md-5 center" style="margin-top: 2rem">
        <div class="col-md-11">
          <p class="lemonada text-center" style="display: flex; align-items: center; justify-content: space-evenly">
            احصل على تطبيقنا على:
          </p>
        </div>
        <div class="row">
          <div class="col-md-4 col-6">
            <a href="https://play.google.com/store/apps/details?id=com.halloumoglu.halloumapp" target="_blank">
              <img src="assets/google-play.png" class="mb-2 center" style="width: 100%" />
            </a>
          </div>
          <div class="col-md-4 col-6">
            <a href="https://apps.apple.com/se/app/halloum/id6446890375" target="_blank">
              <img src="assets/app-store.png" class="mb-2 center" style="width: 100%" />
            </a>
          </div>
          <div class="col-md-4 col-6">
            <a href="https://appgallery.huawei.com/" target="_blank">
              <img src="assets/app-gallery.png" class="mb-2 center" style="width: 100%" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container text-center pb-4">
    <div class="row" style="justify-content: center"></div>
  </div>
  <div class="col-12 footer-copyright py-3 lemonada" style="background-color: #ffc10ec9; color: #005121c7">
    <div class="row">
      <div class="col-4">
        <div class="row">
          <div class="col-md-1">
            <a (click)="goToTR()" style="color: #fff">
              <img src="assets/turkey (1).png" alt="" />
            </a>
          </div>
          <div class="col-md-1">
            <a (click)="goToEN()" style="color: #fff">
              <img src="assets/united-kingdom.png" alt="" />
            </a>
          </div>
        </div>
      </div>
      <div class="col-4 center" style="text-align: center">
        © Copyright
        {{ data | date : "yyyy" }}

        - Developed By
        <a class="lemonada" href="https://truted.com/">TruTed</a>
      </div>
      <div class="col-4">
        <div style="float: right; text-align: end">
          <a href="https://etbis.eticaret.gov.tr/sitedogrulama/6266237422273814" target="_blank"
            ><img style="width: 8%; height: auto" src="assets/logo1.png"
          /></a>
          <img
            style="width: 4%"
            src="https://firebasestorage.googleapis.com/v0/b/halloum-database.appspot.com/o/privacy%2Fpngwing.com.png?alt=media&token=4e7db1fa-82c5-424b-9160-558e5917f914"
            alt="" />
          <img src="assets/creditCard/logo_band_colored.svg" style="width: 30%" alt="" />
        </div>
      </div>
    </div>
  </div>
</footer>
<footer *ngIf="mdScreen" class="page-footer font-small pt-4">
  <div class="container-fluid" style="color: #000">
    <div class="col-xl-3 colrg-3 col-md-4 poppins ml-auto"></div>
  </div>

  <div class="container text-md-left lemonada" style="text-align: right; margin-top: 30px">
    <div class="row">
      <div class="col-md-3">
        <div style="padding-left: 5px">
          <a routerLink="/">
            <img src="assets/h.png" class="center" style="width: 40%" />
          </a>
        </div>
      </div>

      <div class="col-md-5">
        <div class="row">
          <div class="col-md-6 mb-md-0 mb-3" style="padding-top: 20px; text-align: center">
            <h5 class="text-uppercase text-gold arabic" style="font-size: 13px; margin-top: 30px">شركة</h5>
            <ul class="list-unstyled arabic" style="padding-right: 0px !important">
              <li>
                <a class="" routerLink="/about">عن حلوم</a>
              </li>
              <li>
                <a class="" routerLink="/our-team">فريقنا</a>
              </li>
              <li>
                <a class="" routerLink="/contact">اتصل بنا</a>
              </li>
              <li>
                <a class="" routerLink="/branches">فروعنا</a>
              </li>
              <!-- <li>
                                        <a class="" routerLink="/vision-mission">Vision & Mission</a>
                                   </li> -->
              <li>
                <a class="" routerLink="/privacy-policy">سياسة الخصوصية</a>
              </li>
              <li>
                <a class="" routerLink="/membership-agreemant">اتفاقية العضوية</a>
              </li>
            </ul>
          </div>
          <div class="col-md-6 mb-md-0 mb-3" style="text-align: center">
            <h5 class="text-uppercase text-gold arabic" style="font-size: 13px; margin-top: 30px">استطلع</h5>
            <ul class="list-unstyled arabic" style="padding-right: 0px !important">
              <li>
                <a class="" routerLink="/">الرئيسية</a>
              </li>
              <li>
                <a class="" routerLink="/careers">وظائف </a>
              </li>
              <li>
                <a class="" routerLink="/categories">التصنيفات</a>
              </li>
              <li>
                <a class="" routerLink="/gallery">الاستديو</a>
              </li>
              <!-- <li>
                                        <a class="" routerLink="/blog">Blog</a>
                                   </li> -->
              <li>
                <a class="" routerLink="/loyalty-program">Loyalty Program</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="row">
          <div class="col-12">
            <div style="display: flex; justify-content: center">
              <img src="assets/halal.png" style="width: 60px" alt="" />
              <img src="assets/organic.png" style="width: 60px" alt="" />
            </div>
            <div class="footer-social">
              <a href="https://www.facebook.com/Halloumoglu.tr/" target="_blank"><i class="fab fa-facebook-square"></i></a>
              <a href="https://www.instagram.com/halloumoglu.tr/" target="_blank"><i class="fab fa-instagram"></i></a>
              <!-- <a href="https://twitter.com/halloumoglu" target="_blank"><i class="fab fa-twitter"></i></a> -->
              <a
                href="https://wa.me/+905300888034/?text=Welcome%20to%20Halloum%20dairy,%20kindly%20leave%20your%20message%20and%20we%20will%20contact%20you%20back%20as%20soon%20as%20possible."
                ><i class="fab fa-whatsapp" target="_blank"></i
              ></a>
              <!-- <a href="https://t.me/OzgurKenan" target="_blank"><i class="fab fa-telegram"></i></a> -->
              <a href="https://www.youtube.com/c/HALLOUMOGLU" target="_blank"><i class="fab fa-youtube"></i></a>
              <a href="https://vm.tiktok.com/ZSJPNgqPh/" target="_blank"><i class="fab fa-tiktok"></i></a>
              <a href="tel:+905300888034" target="_blank">
                <i class="fas fa-headset"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-5 col-md-5 center" style="margin-top: 2rem">
        <div class="col-md-11">
          <p class="lemonada text-center" style="display: flex; align-items: center; justify-content: space-evenly">
            احصل على تطبيقنا على:
          </p>
        </div>
        <div class="row">
          <div class="col-md-4 col-6">
            <a href="https://play.google.com/store/apps/details?id=com.halloumoglu.halloumapp" target="_blank">
              <img src="assets/google-play.png" class="mb-2 center" style="width: 70%" />
            </a>
          </div>
          <div class="col-md-4 col-6">
            <a href="https://apps.apple.com/se/app/halloum/id6446890375" target="_blank">
              <img src="assets/app-store.png" class="mb-2 center" style="width: 70%" />
            </a>
          </div>
          <div class="col-md-4 col-6">
            <a href="https://appgallery.huawei.com/" target="_blank">
              <img src="assets/app-gallery.png" class="mb-2 center" style="width: 70%" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container text-center pb-4">
    <div class="row" style="justify-content: center"></div>
  </div>
  <div class="footer-copyright py-3 lemonada" style="background-color: #ffc10ec9; color: #005121c7">
    <ul style="text-align: center; list-style: none">
      <li>
        <a (click)="goToTR()" style="color: #fff">
          <img src="assets/turkey (1).png" alt="" />
        </a>

        <a (click)="goToEN()" style="color: #fff">
          <img style="padding-right: 5px" src="assets/united-kingdom.png" alt="" />
        </a>
      </li>
      <li style="padding-top: 10px">
        <p style="font-size: small">
          © Copyright
          {{ data | date : "yyyy" }}

          - Powered
          <a class="lemonada" href="https://truted.com/">TruTed</a>
        </p>
      </li>
      <li>
        <a href="https://etbis.eticaret.gov.tr/sitedogrulama/6266237422273814" target="_blank"
          ><img style="width: 10%; height: auto" src="assets/logo1.png"
        /></a>
        <img
          style="width: 10%"
          src="https://firebasestorage.googleapis.com/v0/b/halloum-database.appspot.com/o/privacy%2Fpngwing.com.png?alt=media&token=4e7db1fa-82c5-424b-9160-558e5917f914"
          alt="" />
        <img src="assets/creditCard/logo_band_colored.svg" style="width: 40%" alt="" />
      </li>
    </ul>
  </div>
</footer>
