<app-header></app-header>
<div *ngIf="spinner">
  <mdb-carousel [isControls]="true" class="carousel slide carousel-fade" [animation]="'fade'">
    <mdb-carousel-item>
      <div class="view w-100" *ngIf="career">
        <img
          style="height: 300px; object-fit: cover"
          class="d-block w-100 img-sm"
          src="{{ career.arabicImage }}"
          width="100%"
          alt="First slide" />
      </div>
    </mdb-carousel-item>
  </mdb-carousel>
  <main class="main">
    <div class="container">
      <div *ngIf="career">
        <div class="row">
          <div class="col-lg-12 col-md-5" style="text-align: right; margin-top: 1%">
            <h3 class="cabin-sketch mb-4" style="color: #005121; text-transform: uppercase; font-weight: 600">
              {{ career.arabicHeader }}
            </h3>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <span [innerHTML]="career.arabicText"></span>
          </div>
          <div class="col-md-12">
            <!-- <p class="poppins mt-2 mb-4">Current opportunities</p> -->
            <div style="text-align: initial" class="col-md-12" *ngFor="let opportunity of opportunities">
              <p class="text-green poppins" style="font-weight: 900">- {{ opportunity.nameAR }}</p>
              <p>
                نوع العمل - <b>{{ opportunity.workTypeAR }}</b>
                <br />
                موقع - <b>{{ opportunity.locationAR }}</b>
                <br />
                موضع - <b>{{ opportunity.positionAR }}</b>
                <br />
                تاريخ الانتهاء - <b>{{ opportunity.endDate | date }}</b>
                <br />
                <span [innerHTML]="opportunity.descriptionAR"></span>
              </p>
              <hr />
            </div>
          </div>
        </div>

        <form class="contact-form mt-4" novalidate [formGroup]="jobForm" #fform="ngForm" (ngSubmit)="onSubmit()">
          <div class="row">
            <div class="col-lg-3 col-md-4">
              <mat-form-field appearance="outline" color="primary">
                <mat-label>الاسم الأول</mat-label>
                <input matInput formControlName="firstname" type="text" placeholder="اسمك الأول" required />
                <mat-error *ngIf="formErrors.firstname">
                  {{ formErrors.firstname }}
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-lg-3 col-md-4">
              <mat-form-field appearance="outline" color="primary">
                <mat-label>اسم العائلة</mat-label>
                <input matInput formControlName="lastname" type="text" placeholder="اسمك الاخير" required />
                <mat-error *ngIf="formErrors.lastname">
                  {{ formErrors.lastname }}
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-lg-3 col-md-4">
              <mat-form-field appearance="outline" color="primary">
                <mat-label>رقم التليفون</mat-label>
                <input matInput formControlName="phone" placeholder="مثال. 90 531 234 56 78" pattern="[0-9]*" type="tel" required />
                <mat-error *ngIf="formErrors.phone">
                  {{ formErrors.phone }}
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-lg-3 col-md-4">
              <mat-form-field appearance="outline" color="primary">
                <mat-label>بريد إلكتروني</mat-label>
                <input matInput formControlName="email" type="email" placeholder="عنوان بريدك  الإلكتروني" required />
                <mat-error *ngIf="formErrors.email">
                  {{ formErrors.email }}
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-lg-3 col-md-4" *ngIf="opportunities.length > 0">
              <mat-form-field appearance="outline" color="primary">
                <mat-label>اسم العمل</mat-label>
                <mat-select formControlName="jobName" required>
                  <mat-option *ngFor="let opportunity of opportunities" class="arabic" [value]="opportunity.nameAR">
                    {{ opportunity.nameAR }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-lg-3 col-md-4">
              <mat-form-field appearance="outline" color="primary">
                <mat-label>تاريخ الميلاد</mat-label>
                <input matInput formControlName="dateBirth" [matDatepicker]="picker" disabled required />
                <mat-datepicker-toggle dir="ltr" matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker dir="ltr" touchUi #picker disabled="false"></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="col-lg-6 col-md-8">
              <mat-form-field appearance="outline" color="primary">
                <mat-label>عنوان</mat-label>
                <input matInput formControlName="address" type="text" placeholder="اسم الشارع - الحي - المدين" required />
                <mat-error *ngIf="formErrors.address">
                  {{ formErrors.address }}
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-lg-3 col-md-4">
              <mat-form-field appearance="outline" color="primary">
                <mat-label>طبيعة التواصل</mat-label>
                <mat-select formControlName="contactType" required>
                  <mat-option value="email">بريد إلكتروني</mat-option>
                  <mat-option value="tel">هاتف</mat-option>
                  <mat-option value="noMatter">لا يهم</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-lg-3 col-md-4">
              <mat-form-field appearance="outline" color="primary">
                <mat-label>الوظيفة المرغوبة؟</mat-label>
                <mat-select formControlName="desiredEmployment" required>
                  <mat-option value="fullTime">وقت كامل</mat-option>
                  <mat-option value="partTime">دوام جزئى</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-lg-6 col-md-8">
              <mat-form-field appearance="outline" color="primary">
                <mat-label>خبرة في العمل</mat-label>
                <input matInput formControlName="workExperience" type="text" placeholder="اخبرنا عن اي خبرة عمل سابقة" required />
                <mat-error *ngIf="formErrors.workExperience">
                  {{ formErrors.workExperience }}
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-lg-6 col-md-12">
              <mat-form-field appearance="outline" color="primary">
                <mat-label>رسالتك</mat-label>
                <textarea matInput formControlName="message" placeholder="تخبرنا المزيد عنك" rows="12"></textarea>
                <mat-hint align="end">{{ jobForm.value.message.length }}/2000</mat-hint>
                <mat-error *ngIf="formErrors.message">
                  {{ formErrors.message }}
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-lg-6 col-md-12">
              <div class="row" style="margin-top: 10%">
                <div class="col-md-6 mb-4">
                  <div class="file">
                    <div class="center" style="width: 100%">
                      <label for="file-upload" class="custom-file-upload arabic">
                        <i class="fas fa-cloud-upload-alt"></i> إضافة السيرة الذاتية
                      </label>
                      <input id="file-upload" (change)="onDrop($event.target.files)" type="file" />
                    </div>
                  </div>
                  <div class="container" *ngIf="file">
                    <div class="row" *ngIf="percentage | async as pct">
                      <div class="container">
                        <mat-progress-bar mode="determinate" [value]="pct"></mat-progress-bar>
                        <p style="margin-bottom: 0rem; font-size: 12px">{{ pct | number }}%</p>
                        <div
                          class="ldBar label-center"
                          style="width: 50%; height: 50%; margin: auto"
                          data-value="35"
                          data-preset="circle"></div>
                      </div>
                    </div>
                    <div *ngIf="snapshot | async as snap">
                      <div *ngIf="downloadURL as url">
                        <p class="poppins" style="color: #00c851 !important; font-size: 12px">تم تحميل السيرة الذاتية</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 mb-4">
                  <a *ngIf="downloadURL" href="{{ downloadURL }}" target="_blank">سيرتك الذاتية</a>
                </div>
                <div class="col-md-6 mt-4">
                  <div class="file">
                    <div class="center" style="width: 100%">
                      <label for="file-upload" class="custom-file-upload arabic">
                        <i class="fas fa-cloud-upload-alt"></i> قم بتحميل الخطاب
                      </label>
                      <input id="file-upload" (change)="onDrop2($event.target.files)" type="file" />
                    </div>
                  </div>
                  <div class="container" *ngIf="file">
                    <div class="row" *ngIf="percentage2 | async as pct">
                      <div class="container">
                        <mat-progress-bar mode="determinate" [value]="pct"></mat-progress-bar>
                        <p style="margin-bottom: 0rem; font-size: 12px">{{ pct | number }}%</p>
                        <div
                          class="ldBar label-center"
                          style="width: 50%; height: 50%; margin: auto"
                          data-value="35"
                          data-preset="circle"></div>
                      </div>
                    </div>
                    <div *ngIf="snapshot2 | async as snap">
                      <div *ngIf="downloadURL2 as url">
                        <p class="poppins arabic" style="color: #00c851 !important; font-size: 12px">تم تحميل الخطاب</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 mt-4">
                  <a *ngIf="downloadURL2" href="{{ downloadURL2 }}" target="_blank">خطابك المرفق</a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-4 center mt-4" style="margin-bottom: 5%">
            <button class="btn btn-primary btn-round btn-block btn-halloum" [disabled]="jobForm.invalid" mdbWavesEffect type="submit">
              إرسال
            </button>
          </div>
        </form>
        <!-- <div class="col-md-8 center">
                    <app-follow-us></app-follow-us>
               </div> -->
      </div>
    </div>
  </main>
</div>

<div class="container col-12" *ngIf="!spinner">
  <div class="view w-100 ph-item">
    <div class="ph-col-12">
      <div style="height: 600px" class="ph-picture" width="100%"></div>
    </div>
  </div>
</div>
<app-footer></app-footer>
