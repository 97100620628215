<main class="main">
  <div class="container">
    <div class="row" *ngIf="status == 'notDone'">
      <div class="col-lg-8 col-md-12 mb-4">
        <div
          class="card card-body shadow"
          style="background-color: #fff; border: none"
        >
          <h3 class="h3-responsive arabic text-center">إنشاء حساب جديد</h3>
          <h5
            class="text-center arabic mt-4"
            *ngIf="errMess"
            style="color: red; font-weight: 600"
          >
            {{ errMess }}
          </h5>
          <form
            class="contact-form mt-4"
            novalidate
            [formGroup]="signUpForm"
            #fform="ngForm"
            (ngSubmit)="onSubmit()"
          >
            <div class="row">
              <div class="col-md-6">
                <mat-form-field
                  class="arabic"
                  appearance="outline"
                  color="primary"
                >
                  <mat-label>الاسم</mat-label>
                  <input
                    matInput
                    formControlName="name"
                    type="text"
                    placeholder="الاسم"
                    required
                  />
                  <mat-error *ngIf="formErrors.name">
                    {{ formErrors.name }}
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <mat-form-field
                  class="arabic"
                  appearance="outline"
                  color="primary"
                >
                  <mat-label>الكنية</mat-label>
                  <input
                    matInput
                    formControlName="lastname"
                    type="text"
                    placeholder="الكنية"
                    required
                  />
                  <mat-error *ngIf="formErrors.lastname">
                    {{ formErrors.lastname }}
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-12">
                <mat-form-field
                  class="arabic"
                  appearance="outline"
                  color="primary"
                >
                  <mat-label>البريد الإلكتروني</mat-label>
                  <input
                    matInput
                    formControlName="email"
                    type="email"
                    placeholder="بريدك الإلكتروني"
                    required
                  />
                  <mat-error *ngIf="formErrors.email">
                    {{ formErrors.email }}
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <mat-form-field
                  class="arabic"
                  appearance="outline"
                  color="primary"
                >
                  <mat-label>كلمة المرور</mat-label>
                  <input
                    matInput
                    formControlName="password"
                    type="password"
                    placeholder="كلمة المرور"
                    required
                  />
                  <mat-error *ngIf="formErrors.password">
                    {{ formErrors.password }}
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <mat-form-field
                  class="arabic"
                  appearance="outline"
                  color="primary"
                >
                  <mat-label>تأكيد كلمة المرور</mat-label>
                  <input
                    matInput
                    formControlName="confirmPass"
                    type="password"
                    placeholder="تأكيد كلمة المرور"
                    required
                  />
                  <mat-error *ngIf="formErrors.confirmPass">
                    Passwords do not match
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-12 col-12 mb-2">
                <section
                  class="example-section"
                  style="margin-right: 5px; float: right !important"
                >
                  <mat-checkbox
                    formControlName="checkbox"
                    color="primary"
                    class="example-margin"
                  >
                  </mat-checkbox>
                  <span class="arabic" style="margin-right: 5px">
                    لقد قرأت وقبلت
                    <a routerLink="/membership-agreemant" target="_blank">
                      اتفاقية العضوية
                    </a>
                    و
                    <a routerLink="/privacy-policy" target="_blank">
                      سياسة الخصوصية.
                    </a>
                  </span>
                </section>
              </div>
              <div class="col-md-12 col-12 mb-2">
                <section
                  class="example-section"
                  style="margin-right: 5px; float: right !important"
                >
                  <mat-checkbox
                    formControlName="emailSubscription"
                    color="primary"
                    class="example-margin arabic"
                  ></mat-checkbox>
                  <span class="arabic" style="margin-right: 5px">
                    احصل على أحدث العروض والخصومات لدينا عن طريق البريد
                    الإلكتروني.
                  </span>
                </section>
              </div>
            </div>
            <div class="col-md-12">
              <button
                [hidden]="loading"
                class="btn btn-primary btn-round btn-block btn-halloum mb-2 arabic"
                [disabled]="!signUpForm.value.checkbox || signUpForm.invalid"
                mdbWavesEffect
                type="submit"
              >
                إنشاء حساب
              </button>

              <button
                *ngIf="loading"
                class="btn btn-primary btn-round btn-block btn-halloum mb-2 arabic"
                [disabled]="true"
                mdbWavesEffect
                type="submit"
              >
                جاري إنشاء الحساب
                <i
                  style="margin-left: 3px"
                  class="fa fa-spinner fa-spin fa-lg"
                ></i>
              </button>
              <div class="arabic" style="float: right !important">
                هل لديك حساب؟
                <a routerLink="/sign-in"> تسجيل الدخول </a>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="col-lg-4 col-md-8 center">
        <div
          class="card card-body shadow"
          style="background-color: #fff; border: none"
        >
          <h3 class="h3-responsive arabic text-center">أو</h3>
          <hr />
          <a
            class="btn shadow mt-4 mb-4 arabic"
            style="background-color: #4285f4; color: #fff"
            (click)="authService.GoogleAuth(returnUrl)"
          >
            <i
              class="fab fa-google"
              style="margin-left: 10px; font-size: 19px"
            ></i>
            تسجيل دخول عن طريق غوغل
          </a>
          <a
            class="btn shadow mb-4 arabic"
            style="background-color: #3b5998; color: #fff"
            (click)="authService.FacebookAuth(returnUrl)"
          >
            <i
              class="fab fa-facebook-square"
              style="margin-left: 10px; font-size: 20px"
            ></i>
            تسجيل دخول عن طريق فيسبوك
          </a>
          <hr />
          <img src="assets/h.png" width="50%" class="center mt-4 mb-4" />
        </div>
      </div>
      <div class="col-md-12 center">
        <app-follow-us></app-follow-us>
      </div>
    </div>
    <div class="animated bounceInDown row" *ngIf="status == 'done'">
      <div class="col-md-9 center" style="margin-top: 10%">
        <div
          class="card card-body shadow mb-2"
          style="background-color: #fff; border: none"
        >
          <h3 class="h3-responsive arabic text-center">
            <i class="fas fa-check-circle" style="color: #00c851"></i> A
            verification link has been sent to your email account
          </h3>
          <hr />
          <p class="arabic">
            Please click on the link that has just been sent to your email
            account to verify your email and continue the registration process.
          </p>
          <p class="arabic mt-4" style="margin-left: 5px">
            Go to
            <a routerLink="/">Home page</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</main>
