import { Component, OnInit, ViewChild, Inject } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { AuthService } from "../services/auth.service";
import { AngularFirestore } from "@angular/fire/firestore";
import { Title } from "@angular/platform-browser";
import { IyzicoService } from "../services/iyzico.service";
import { IpService } from "../services/ip.service";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { customAlphabet } from "nanoid";
import * as CryptoJS from "crypto-js";
import { environment } from "src/environments/environment.prod";
import { SessionStorageService } from "../services/session-storage.service";
import { SwiperOptions } from "swiper";
import { DeleteDialogComponent } from "./delete-dialog/delete-dialog.component";

export interface DialogData {
  id: string;
  name: string;
  lastname: string;
  phone: string;
  district: string;
  city: string;
  country: string;
  address: string;
  addressTitle: string;
}

export interface privacyData {
  preliminaryInformationForm: any;
  distanceSalesAgreement: any;
}

@Component({
  selector: "app-cart",
  templateUrl: "./cart.component.html",
  styleUrls: ["./cart.component.scss"],
})
export class CartComponent implements OnInit {
  submitted: boolean = false;
  disabledButton: boolean = true;

  configProducts: SwiperOptions = {
    height: 300,
    breakpoints: {
      1024: {
        slidesPerView: 4,
        spaceBetween: 30,
      },
      768: {
        slidesPerView: 3,
        spaceBetween: 20,
      },
      640: {
        slidesPerView: 2,
        spaceBetween: 10,
      },
      320: {
        slidesPerView: 2,
        spaceBetween: 10,
      },
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    pagination: {
      el: ".swiper-pagination",
      type: "bullets",
    },
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
    autoHeight: true,
    loop: true,
    speed: 500,
    observer: true,
    observeParents: true,
  };

  address: any;
  addresses: any[] = [];
  addressSelected: boolean = false;
  cardSelected: boolean = false;
  checkboxAddBillingAddress: any = true;
  billingAddress: any;

  cartProducts: any[] = [];
  basketItems: any[] = [];
  showSpinner: boolean = false;
  total: number = 0;
  totalBeforeDiscount: number = 0;
  user: any;
  id: any;

  ccNumber: any;
  emailVerified: boolean = false;
  verificationSent: boolean = false;

  addCardForm: boolean = true;
  cardForm: FormGroup;
  months: any[] = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
  years: any[] = [
    "2022",
    "2023",
    "2024",
    "2025",
    "2026",
    "2027",
    "2028",
    "2029",
    "2030",
    "2031",
    "2032",
    "2033",
    "2034",
    "2035",
    "2036",
    "2037",
    "2038",
    "2039",
    "2040",
    "2041",
    "2042",
    "2043",
    "2044",
    "2045",
  ];
  paymentCard: any;
  shippingCost: any;

  nanoid = customAlphabet("0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ", 20);
  nanoidNumbers = customAlphabet("0123456789", 10);
  cards: any[] = [];

  startPayment: boolean = false;
  getStepper: boolean = false;
  shipping: any;
  checkboxAgreement: boolean = false;
  payWith3DS: boolean = true;
  privacyContract: any;
  paymentData: any;

  cartId: any;

  preliminaryInformationForm: any;
  distanceSalesAgreement: any;

  categoryIds: string[] = [];
  suggestedProducts: any[] = [];

  @ViewChild("fform") cardFormDirective;

  formErrors = {
    cardAlias: "",
    cardHolderName: "",
    cardNumber: "",
    expireMonth: "",
    expireYear: "",
    cvc: "",
    registerCard: "",
  };

  validationMessages = {
    cardAlias: {
      required: "يرجى إدخال الاسم المستعار",
    },
    cardHolderName: {
      required: "يرجى إدخال إسم صاحب البطاقة",
    },
    cardNumber: {
      required: "يرجى إدخال رقم البطاقة",
      pattern: "يجب أن يحتوي رقم البطاقة على أرقام فقط.",
    },
    expireMonth: {
      required: "يرجى إدخال الشهر",
    },
    expireYear: {
      required: "يرجى إدخال السنة",
    },
    cvc: {
      required: "يرجى إدخال رمز ال CVC",
      minlength: "minlength",
      maxlength: "maxlength",
    },
    registerCard: {
      required: "يرجى إدخال حفظ البطاقة",
    },
  };

  constructor(
    public db: AngularFirestore,
    public authServices: AuthService,
    public router: Router,
    public snackBar: MatSnackBar,
    private title: Title,
    public dialog: MatDialog,
    private fb: FormBuilder,
    private iyzicoService: IyzicoService,
    private sessionStorageService: SessionStorageService
  ) {
    this.title.setTitle("حلوم اوغلو - السلة");
  }

  ngOnInit(): void {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
    this.cartId = this.nanoidNumbers();
    this.showSpinner = true;
    this.emailVerified = JSON.parse(localStorage.getItem("user")).emailVerified;
    this.getUserInfo();
    this.db.firestore
      .collection("content")
      .doc("shipping")
      .get()
      .then((res) => {
        this.shipping = {
          freeShippingLimit: res.data().freeShippingLimit,
          shippingCost: res.data().shippingCost,
        };
      });
    this.db.firestore
      .collection("content")
      .doc("privacyAR")
      .get()
      .then((res) => {
        this.privacyContract = res.data();
      });
    this.createCardForm();
  }

  getUserInfo() {
    let uid = JSON.parse(localStorage.getItem("user")).uid;
    this.db.firestore
      .collection("users")
      .doc(uid)
      .get()
      .then((documentSnapshots) => {
        this.user = documentSnapshots.data();
        this.id = documentSnapshots.id;
        this.getProductsSnapshots();
      });
  }

  check() {
    if (this.cartProducts.length > 0) {
      var i = 0;
      this.cartProducts.forEach((el) => {
        if (el.count > el.stock) {
          i++;
        }
      });
      if (i == 0) {
        this.disabledButton = false;
      } else {
        this.disabledButton = true;
      }
    } else {
      this.disabledButton = true;
    }
  }

  SendVerificationMess() {
    this.authServices.SendVerificationMail().then(() => {
      this.verificationSent = true;
      this.emailVerified = true;
    });
  }

  loadUserAddresses() {
    if (this.emailVerified == true) {
      if (this.user.phone == null || this.user.address == null || this.user.city == null || this.user.country == null) {
        this.openSnackBar3("يجب عليك استكمال المعلومات الخاصة بك في ملف التعريف الخاص بك! ❌", "اكمل الآن", 10000);
        setTimeout(() => {
          this.router.navigate(["/profile"], { queryParams: { returnUrl: "/cart" } });
        }, 7000);
      } else {
        this.getStepper = !this.getStepper;
        this.showSpinner = true;
        this.db.firestore
          .collection("users")
          .doc(this.id)
          .collection("addresses")
          .get()
          .then((res) => {
            for (let i in res.docs) {
              this.addresses[i] = res.docs[i].data();
              this.addresses[i].id = res.docs[i].id;
              this.addresses[i].selected = false;
            }
            this.loadUserCards();
          });
      }
    } else {
      this.openSnackBar2("لم يتم تأكيد حسابك! ❌", "تأكيد الحساب", 10000);
    }
  }

  loadUserCards() {
    this.cards = [];
    if (this.user.cardUserKey) {
      const data = {
        locale: "en",
        conversationId: this.nanoid(),
        cardUserKey: this.user.cardUserKey,
      };
      this.iyzicoService.getUserCards(data).subscribe(
        (res) => {
          for (let i in res.cardDetails) {
            this.cards[i] = res.cardDetails[i];
            this.cards[i].id = i;
            this.cards[i].selected = false;
            if (this.cards[i].cardAssociation == "MASTER_CARD") {
              this.cards[i].cardAssociationImg = "assets/creditCard/mastercard.svg";
            } else if (this.cards[i].cardAssociation == "VISA") {
              this.cards[i].cardAssociationImg = "assets/creditCard/visa.svg";
            } else if (this.cards[i].cardAssociation == "AMERICAN_EXPRESS") {
              this.cards[i].cardAssociationImg = "assets/creditCard/ae.png";
            } else {
              this.cards[i].cardAssociationImg = "assets/creditCard/visa.svg";
            }
          }
          this.showSpinner = false;
        },
        (err) => {
          this.openSnackBar("انتهت صلاحية جلسة العمل الخاصة بك. الرجاء إعادة تسجيل الدخول إلى الحساب مرة أخرى.", "❌", 10000);
        }
      );
    } else {
      this.cards = [];
      this.showSpinner = false;
    }
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(AddressDialog, {
      width: "600px",
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.billingAddress = result;
    });
  }

  sortDuplicatedItem(array): any[] {
    const convert = (arr) => {
      const res = {};
      arr.forEach((obj) => {
        const key = `${obj.id}`;
        if (!res[key]) {
          res[key] = { ...obj, count: 0 };
        }
        res[key].count += 1;
      });
      return Object.values(res);
    };
    return convert(array);
  }

  setContracts() {
    let date = new Date();
    let deliveryDate = [new Date(), new Date()];
    deliveryDate[0].setDate(deliveryDate[0].getDate() + 3);
    deliveryDate[1].setDate(deliveryDate[1].getDate() + 5);
    let billingAddress = this.billingAddressData();
    this.basketItems = this.getTotalItem();
    let items = this.sortDuplicatedItem(this.basketItems);
    let tr = " ";
    for (let i in items) {
      tr =
        tr +
        `<tr>
      <td width="208" valign="top" style="width:155.8pt;border:solid windowtext 1.0pt;
      mso-border-alt:solid windowtext .5pt;padding:0in 5.4pt 0in 5.4pt">
      <p class="MsoNormal" align="center" dir="RTL" style="margin-bottom:0in;text-align:
      center;line-height:normal;direction:rtl;unicode-bidi:embed"><span lang="AR-SA" style="font-size:14.0pt;font-family:&quot;Arial&quot;,sans-serif;mso-ascii-theme-font:
      minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi;
      mso-ansi-language:TR">${items[i].nameAR}<o:p></o:p></span></p>
      </td>
      <td width="208" valign="top" style="width:155.85pt;border:solid windowtext 1.0pt;
      border-right:none;mso-border-right-alt:solid windowtext .5pt;mso-border-alt:
      solid windowtext .5pt;padding:0in 5.4pt 0in 5.4pt">
      <p class="MsoNormal" align="center" dir="RTL" style="margin-bottom:0in;text-align:
      center;line-height:normal;direction:rtl;unicode-bidi:embed"><span lang="AR-SA" style="font-size:14.0pt;font-family:&quot;Arial&quot;,sans-serif;mso-ascii-theme-font:
      minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi;
      mso-ansi-language:TR">${items[i].count}<o:p></o:p></span></p>
      </td>
      <td width="208" valign="top" style="width:155.85pt;border:solid windowtext 1.0pt;
      border-right:none;mso-border-right-alt:solid windowtext .5pt;mso-border-alt:
      solid windowtext .5pt;padding:0in 5.4pt 0in 5.4pt">
      <p class="MsoNormal" align="center" dir="RTL" style="margin-bottom:0in;text-align:
      center;line-height:normal;direction:rtl;unicode-bidi:embed"><span lang="AR-SA" style="font-size:14.0pt;font-family:&quot;Arial&quot;,sans-serif;mso-ascii-theme-font:
      minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi;
      mso-ansi-language:TR">${items[i].price * items[i].count} TRY <o:p></o:p></span></p>
      </td>
     </tr>`;
    }
    const data = {
      itemsTable: `  <div align="right">
      <table class="MsoTableGrid" dir="rtl" border="1" cellspacing="0" cellpadding="0" style="border: none;">
       <tbody>
       <tr>
        <td width="208" valign="top" style="width:155.8pt;border:solid windowtext 1.0pt;
        mso-border-alt:solid windowtext .5pt;padding:0in 5.4pt 0in 5.4pt">
        <p class="MsoNormal" align="center" dir="RTL" style="margin-bottom:0in;text-align:
        center;line-height:normal;direction:rtl;unicode-bidi:embed"><span lang="AR-SA" style="font-size:14.0pt;font-family:&quot;Arial&quot;,sans-serif;mso-ascii-theme-font:
        minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi;
        mso-ansi-language:TR">اسم المنتج<o:p></o:p></span></p>
        </td>
        <td width="208" valign="top" style="width:155.85pt;border:solid windowtext 1.0pt;
        border-right:none;mso-border-right-alt:solid windowtext .5pt;mso-border-alt:
        solid windowtext .5pt;padding:0in 5.4pt 0in 5.4pt">
        <p class="MsoNormal" align="center" dir="RTL" style="margin-bottom:0in;text-align:
        center;line-height:normal;direction:rtl;unicode-bidi:embed"><span lang="AR-SA" style="font-size:14.0pt;font-family:&quot;Arial&quot;,sans-serif;mso-ascii-theme-font:
        minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi;
        mso-ansi-language:TR">الكمية<o:p></o:p></span></p>
        </td>
        <td width="208" valign="top" style="width:155.85pt;border:solid windowtext 1.0pt;
        border-right:none;mso-border-right-alt:solid windowtext .5pt;mso-border-alt:
        solid windowtext .5pt;padding:0in 5.4pt 0in 5.4pt">
        <p class="MsoNormal" align="center" dir="RTL" style="margin-bottom:0in;text-align:
        center;line-height:normal;direction:rtl;unicode-bidi:embed"><span lang="AR-SA" style="font-size:14.0pt;font-family:&quot;Arial&quot;,sans-serif;mso-ascii-theme-font:
        minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi;
        mso-ansi-language:TR">المجموع<o:p></o:p></span></p>
        </td>
       </tr>
       ${tr}
      </tbody></table>
      </div>`,
      cartId: this.cartId,
      shippingCost: this.shippingCost,
      orderDate: date.toLocaleDateString("ar-UA", { year: "numeric", month: "short", day: "2-digit" }),
      deliveryDate:
        deliveryDate[0].toLocaleDateString("ar-UA", { year: "numeric", month: "short", day: "2-digit" }) +
        " - " +
        deliveryDate[1].toLocaleDateString("en-EN", { year: "numeric", month: "short", day: "2-digit" }),
      total: this.total,
      buyerName: this.user.name + " " + this.user.lastname,
      buyerAddress: this.user.address,
      buyerPhone: this.user.phone,
      buyerEmail: this.user.email,
      contactName: this.address.name + " " + this.address.lastname,
      shippingAddress: this.address.address + " " + this.address.district + " " + this.address.city + " Turkey",
      shippingPhone: this.address.phone,
      billingContactName: billingAddress.contactName,
      billingAddress: billingAddress.address + " " + billingAddress.city + " " + billingAddress.country,
      billingPhone: billingAddress.phone,
    };

    this.preliminaryInformationForm = this.privacyContract.arabic[7].description
      .replace(/{{itemsTable}}/g, data.itemsTable)
      .replace(/{{shippingCost}}/g, data.shippingCost)
      .replace(/{{orderDate}}/g, data.orderDate)
      .replace(/{{deliveryDate}}/g, data.deliveryDate)
      .replace(/{{total}}/g, data.total + " TRY")
      .replace(/{{buyerName}}/g, data.buyerName)
      .replace(/{{buyerAddress}}/g, data.buyerAddress)
      .replace(/{{buyerPhone}}/g, data.buyerPhone)
      .replace(/{{buyerEmail}}/g, data.buyerEmail)
      .replace(/{{contactName}}/g, data.contactName)
      .replace(/{{shippingAddress}}/g, data.shippingAddress)
      .replace(/{{shippingPhone}}/g, data.shippingPhone)
      .replace(/{{billingContactName}}/g, data.billingContactName)
      .replace(/{{billingAddress}}/g, data.billingAddress)
      .replace(/{{billingPhone}}/g, data.billingPhone);

    this.distanceSalesAgreement = this.privacyContract.arabic[8].description
      .replace(/{{cartId}}/g, data.cartId)
      .replace(/{{itemsTable}}/g, data.itemsTable)
      .replace(/{{shippingCost}}/g, data.shippingCost)
      .replace(/{{orderDate}}/g, data.orderDate)
      .replace(/{{deliveryDate}}/g, data.deliveryDate)
      .replace(/{{total}}/g, data.total + " TRY")
      .replace(/{{buyerName}}/g, data.buyerName)
      .replace(/{{buyerAddress}}/g, data.buyerAddress)
      .replace(/{{buyerPhone}}/g, data.buyerPhone)
      .replace(/{{buyerEmail}}/g, data.buyerEmail)
      .replace(/{{contactName}}/g, data.contactName)
      .replace(/{{shippingAddress}}/g, data.shippingAddress)
      .replace(/{{shippingPhone}}/g, data.shippingPhone)
      .replace(/{{billingContactName}}/g, data.billingContactName)
      .replace(/{{billingAddress}}/g, data.billingAddress)
      .replace(/{{billingPhone}}/g, data.billingPhone);
  }

  selectionChange(event) {
    if (event.previouslySelectedIndex == 0 && event.selectedIndex == 1) {
      this.setContracts();
    }
    if (event.previouslySelectedIndex == 1 && event.selectedIndex == 2) {
      this.getSelectedCard();
    }
  }

  openAgreementDialog(): void {
    const dialogRef = this.dialog.open(AgreementDialog, {
      width: "650px",
      data: {
        preliminaryInformationForm: this.preliminaryInformationForm,
        distanceSalesAgreement: this.distanceSalesAgreement,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(result);
    });
  }

  createCardForm() {
    this.cardForm = this.fb.group({
      cardAlias: ["", [Validators.required]],
      cardHolderName: ["", [Validators.required]],
      cardNumber: ["", [Validators.required]],
      expireMonth: ["", [Validators.required]],
      expireYear: ["", [Validators.required]],
      cvc: ["", [Validators.required, Validators.minLength(3), Validators.maxLength(4)]],
      registerCard: ["1"],
    });

    this.cardForm.valueChanges.subscribe((data) => this.onValueChanged(data));

    this.onValueChanged(); //(re)set form validation messages
  }

  onValueChanged(data?: any) {
    if (!this.cardForm) {
      return;
    }
    const form = this.cardForm;
    for (const field in this.formErrors) {
      if (this.formErrors.hasOwnProperty(field)) {
        // clear previous erroe message (if any)
        this.formErrors[field] = "";
        const control = form.get(field);
        if (control && control.dirty && !control.valid) {
          const messages = this.validationMessages[field];
          for (const key in control.errors) {
            if (control.errors.hasOwnProperty(key)) {
              this.formErrors[field] += messages[key] + " ";
            }
          }
        }
      }
    }
  }

  resetCardForm() {
    this.cardForm.reset({
      cardAlias: "",
      cardHolderName: "",
      cardNumber: "",
      expireMonth: "",
      expireYear: "",
      cvc: "",
      registerCard: "",
    });
    this.cardFormDirective.resetForm();
  }

  addPaymentMethod() {
    this.paymentCard = null;
    if (this.addCardForm) {
      if (this.cardForm.value.registerCard == true) {
        if (this.user.cardUserKey) {
          this.paymentCard = {
            ...this.cardForm.value,
            cardUserKey: this.user.cardUserKey,
            cardNumber: this.cardForm.value.cardNumber.replace(/\s/g, ""),
            registerCard: "1",
          };
        } else {
          let cardToSave = {
            ...this.cardForm.value,
            cardNumber: this.cardForm.value.cardNumber.replace(/\s/g, ""),
            registerCard: "1",
          };
          var EncryptInfo = encodeURIComponent(CryptoJS.AES.encrypt(JSON.stringify(cardToSave), environment.secretKey).toString());
          this.iyzicoService
            .createUserAndCard({
              card: EncryptInfo,
              locale: "en",
              conversationId: this.nanoid(),
              externalId: this.nanoid(),
            })
            .subscribe(
              (res: any) => {
                this.paymentCard = {
                  cardToken: res.cardToken,
                  cardUserKey: res.cardUserKey,
                };
              },
              (err) => {
                this.openSnackBar("حدث خطأ", "❌", 10000);
              }
            );
        }
      } else {
        this.paymentCard = {
          ...this.cardForm.value,
          cardNumber: this.cardForm.value.cardNumber.replace(/\s/g, ""),
          registerCard: "0",
        };
      }
    }
  }

  selectAddress(id) {
    this.addressSelected = true;
    for (let i in this.addresses) {
      this.addresses[i].selected = false;
    }
    this.addresses[id].selected = true;
    this.address = this.addresses[id];
  }

  selectCard(id) {
    this.paymentCard = null;
    if (!this.addCardForm) {
      this.cardSelected = true;
      for (let i in this.cards) {
        this.cards[i].selected = false;
      }
      this.cards[id].selected = true;
    }
  }

  getSelectedCard() {
    if (!this.addCardForm) {
      let index = this.cards.findIndex(({ selected }) => selected === true);
      this.paymentCard = {
        cardToken: this.cards[index].cardToken,
        cardUserKey: this.user.cardUserKey,
      };
    }
  }

  openAddCardForm() {
    this.addCardForm = true;
  }

  async countIncrement(product, incrementValue: number, i) {
    let cart: Array<any> = this.user.cart;
    const index = cart.findIndex((item) => {
      return item.productId === product.id;
    });

    if ((incrementValue == -1 && product.count > 1) || (incrementValue == 1 && product.count >= 1)) {
      this.cartProducts[i].count = +product.count + incrementValue;
      cart[index].count = this.cartProducts[i].count;
      this.check();
      await this.db.collection("users").doc(this.id).set(
        {
          cart: cart,
        },
        {
          merge: true,
        }
      );
      this.getTotal();
    }
  }

  async getTotal() {
    this.total = 0;
    this.totalBeforeDiscount = 0;
    for (var product of this.cartProducts) {
      if (product.discountType == "noDiscount") {
        this.total = this.total + product.price * product.count;
        this.totalBeforeDiscount = this.totalBeforeDiscount + product.price * product.count;
      } else if (product.discountType == "discount") {
        let actPrice = product.price - product.discount;
        this.total = this.total + actPrice * product.count;
        this.totalBeforeDiscount = this.totalBeforeDiscount + product.price * product.count;
      } else if (product.discountType == "percent") {
        let actPrice = product.price - product.price * (product.discount / 100);
        this.total = this.total + actPrice * product.count;
        this.totalBeforeDiscount = this.totalBeforeDiscount + product.price * product.count;
      }
    }

    if (this.total >= this.shipping.freeShippingLimit) {
      this.shippingCost = 0;
    } else if (this.total < this.shipping.freeShippingLimit) {
      this.shippingCost = this.shipping.shippingCost;
    } else {
      this.shippingCost = this.shipping.shippingCost;
    }
  }

  getTotalItem() {
    let items = [];
    let cartItems = this.cartProducts;
    for (let i in cartItems) {
      const originalPrice = cartItems[i].price;
      var price = 0;
      for (let j = 0; j < cartItems[i].count; j++) {
        if (cartItems[i].discountType == "noDiscount") {
          price = originalPrice;
        } else if (cartItems[i].discountType == "discount") {
          price = originalPrice - cartItems[i].discount;
        } else if (cartItems[i].discountType == "percent") {
          price = originalPrice - originalPrice * (cartItems[i].discount / 100);
        }
        cartItems[i].price = price;
        items.push(cartItems[i]);
      }
    }
    return items;
  }

  getTotalWithShipping() {
    var total = this.total + this.shippingCost;
    return total;
  }

  deleteCartProduct(index: number): void {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      width: "600px",
      data: {
        product: this.cartProducts[index],
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (result.confirm) {
          this.user.cart.splice(index, 1);
          this.db.firestore
            .collection("users")
            .doc(this.id)
            .set(
              {
                cart: this.user.cart,
              },
              { merge: true }
            )
            .then(() => {
              this.getUserInfo();
            })
            .catch((error) => {
              console.log(error);
            });
        }
      }
    });
  }

  deleteBillingAddress() {
    this.billingAddress = null;
  }

  async getProductsSnapshots() {
    this.cartProducts = [];
    let cartItem = [];
    this.showSpinner = true;
    let j = 0;
    if (this.user.cart) {
      for (let i = 0; i < this.user.cart.length; i++) {
        await this.db.firestore
          .collection("products")
          .doc(this.user.cart[i].productId)
          .get()
          .then((product) => {
            if (product.exists) {
              this.cartProducts[j] = product.data();
              this.cartProducts[j].id = product.id;
              this.cartProducts[j].count = this.user.cart[i].count;
              this.categoryIds.push(product.data().categoryId);
              j++;
            } else {
              cartItem = this.user.cart;
              cartItem.splice(i, 1);
              this.db.firestore.collection("users").doc(this.id).set(
                {
                  cart: cartItem,
                },
                { merge: true }
              );
            }
          });
      }
      this.check();
      this.getSuggestedProducts();
      this.getTotal();
      this.showSpinner = false;
    } else {
      this.cartProducts = [];
      this.showSpinner = false;
    }
  }

  getSuggestedProducts() {
    let filteredArray = [...new Set(this.categoryIds)];
    filteredArray.forEach((item) => {
      this.db.firestore
        .collection("products")
        .where("hidden", "==", false)
        .where("categoryId", "==", item)
        .limit(32)
        .get()
        .then((documentSnapshots) => {
          for (var i in documentSnapshots.docs) {
            this.suggestedProducts[i] = documentSnapshots.docs[i].data();
            this.suggestedProducts[i].id = documentSnapshots.docs[i].id;
            this.suggestedProducts[i].hover = false;
          }
        });
    });
  }

  openSnackBar(message, action, time) {
    this.snackBar.open(message, action, {
      duration: time,
      horizontalPosition: "right",
      direction: "rtl",
    });
  }

  openSnackBar2(message, action, time) {
    let snackBarRef = this.snackBar.open(message, action, {
      duration: time,
      horizontalPosition: "center",
      direction: "rtl",
      panelClass: "snackbar",
    });
    snackBarRef.onAction().subscribe(() => {
      this.SendVerificationMess();
    });
  }

  openSnackBar3(message, action, time) {
    let snackBarRef = this.snackBar.open(message, action, {
      duration: time,
      horizontalPosition: "center",
      direction: "rtl",
      panelClass: "snackbar",
    });
    snackBarRef.onAction().subscribe(() => {
      this.router.navigate(["/profile"], { queryParams: { returnUrl: "/cart" } });
    });
  }

  deleteCardByCardToken(cardToken) {
    const data = {
      locale: "en",
      conversationId: this.nanoid(),
      cardUserKey: this.user.cardUserKey,
      cardToken: cardToken,
    };
    this.iyzicoService.deleteCardByCardToken(data).subscribe(
      (res) => {
        this.openSnackBar("تم حذف بطاقتك بنجاح", "✅", 3000);
        let index = this.cards.findIndex((card) => card.cardToken === cardToken);
        this.user.cart.splice(index, 1);
      },
      (err) => {
        this.openSnackBar("انتهت صلاحية جلسة العمل الخاصة بك. الرجاء إعادة تسجيل الدخول إلى الحساب مرة أخرى.", "❌", 10000);
      }
    );
  }

  billingAddressData() {
    if (this.checkboxAddBillingAddress == false) {
      if (this.billingAddress.billingType == "corporate") {
        return {
          contactName: this.billingAddress.name + " " + this.billingAddress.lastname,
          city: this.billingAddress.city,
          country: this.billingAddress.country,
          address: this.billingAddress.address,
          billingType: this.billingAddress.billingType,
          TIN: this.billingAddress.TIN,
          taxAdministration: this.billingAddress.taxAdministration,
          companyName: this.billingAddress.companyName,
          phone: this.billingAddress.phone,
        };
      } else if (this.billingAddress.billingType == "individual") {
        return {
          contactName: this.billingAddress.name + " " + this.billingAddress.lastname,
          city: this.billingAddress.city,
          country: this.billingAddress.country,
          address: this.billingAddress.address,
          billingType: this.billingAddress.billingType,
          phone: this.billingAddress.phone,
          tc: this.billingAddress.tc,
        };
      }
    } else if (this.checkboxAddBillingAddress == true) {
      return {
        contactName: this.address.name + " " + this.address.lastname,
        city: this.address.city,
        country: "Turkey",
        address: this.address.address,
        phone: this.address.phone,
        district: this.address.district,
      };
    }
  }

  checkoutPayment() {
    var ipAddress = "85.34.78.112";
    this.startPayment = true;
    // this.http.get("http://api.ipify.org/?format=json").subscribe((res: any) => {
    //   ipAddress = res.ip;
    // });
    const data = {
      locale: "ar",
      conversationId: this.nanoid(),
      price: this.total,
      paidPrice: this.getTotalWithShipping(),
      currency: "TRY",
      installment: "1",
      basketId: this.cartId,
      paymentChannel: "WEB",
      paymentGroup: "PRODUCT",
      paymentCard: this.paymentCard,
      buyer: {
        id: this.id,
        name: this.user.name,
        surname: this.user.lastname,
        gsmNumber: this.user.phone,
        identityNumber: "11111111111",
        lastLoginDate: new Date(parseInt(JSON.parse(localStorage.getItem("user")).lastLoginAt)).toISOString(),
        registrationDate: new Date(parseInt(JSON.parse(localStorage.getItem("user")).createdAt)).toISOString(),
        registrationAddress: this.user.address,
        ip: ipAddress,
        city: this.user.city,
        country: this.user.country,
      },
      shippingAddress: {
        contactName: this.address.name + " " + this.address.lastname,
        city: this.address.city,
        country: "Turkey",
        address: this.address.address,
        phone: this.address.phone,
        district: this.address.district,
      },
      billingAddress: this.billingAddressData(),
      preliminaryInformationForm: this.preliminaryInformationForm,
      distanceSalesAgreement: this.distanceSalesAgreement,
      basketItems: this.basketItems.map((item) => {
        return {
          id: item.id,
          name: item.nameEN,
          category1: item.categoryId,
          itemType: "PHYSICAL",
          price: item.price,
          nameEN: item.nameEN,
          nameTR: item.nameTR,
          nameAR: item.nameAR,
          markaEN: item.markaEN,
          markaTR: item.markaTR,
          markaAR: item.markaAR,
          image: item.image,
          productStatus: "in progress",
        };
      }),
    };
    var EncryptInfo = encodeURIComponent(CryptoJS.AES.encrypt(JSON.stringify(data), environment.secretKey).toString());
    if (this.payWith3DS == true) {
      this.iyzicoService
        .initialize3DSPayment(
          {
            encryptInfo: EncryptInfo,
          },
          data.basketId
        )
        .subscribe(
          (res) => {
            if (res.status == "success") {
              let html = res.html;
              if (html.includes("function ")) {
                let funcName = res.html.split("function ")[1].split("()")[0];
                let script = `window.onload = ${funcName}()`;
                html = html.replace("</body>", `<script> ${script} </script></body>`);
                window.open(" ", "_self").document.write(html);
              } else {
                window.open(" ", "_self").document.write(html);
              }
            } else {
              this.openSnackBar(res.errorMessage, "❌", 10000);
              this.startPayment = false;
            }
          },
          (err) => {
            this.openSnackBar("عملية الدفع قد فشلت، يرجى المحاولة مرة أخرى في وقت لاحق!", "❌", 10000);
            this.startPayment = false;
          }
        );
    }
    // else if (this.payWith3DS == false) {
    //   this.iyzicoService.createPayment({
    //     encryptInfo: EncryptInfo
    //   }, data.basketId).subscribe((res) => {
    //     if (res.status == 'success') {
    //       this.openSnackBar('تمت عملية الدفع بنجاح', '✅', 5000)
    //       this.router.navigate(['/payment'], {
    //         queryParams: {
    //           status: res.status,
    //           cartId: res.basketId,
    //           paymentId: res.paymentId,
    //           price: res.price,
    //           paidPrice: res.paidPrice,
    //         }
    //       });
    //     }
    //     else {
    //       this.openSnackBar(res.errorMessage, '❌', 10000)
    //       this.startPayment = false;
    //     }
    //   }, err => {
    //     this.openSnackBar('عملية الدفع قد فشلت، يرجى المحاولة مرة أخرى في وقت لاحق!', '❌', 10000)
    //     this.startPayment = false;
    //   })
    // }
  }

  async addToCart2(productId) {
    let cart = [];
    if (this.authServices.isLoggedIn) {
      let uid = JSON.parse(localStorage.getItem("user")).uid;
      await this.db.firestore
        .collection("users")
        .doc(uid)
        .get()
        .then((res) => {
          if (res.data().cart) {
            cart = res.data().cart;
          }
        });
      const index = cart.findIndex((item) => {
        return item.productId === productId;
      });
      if (index < 0) {
        let product = {
          productId: productId,
          count: 1,
        };
        cart.push(product);
      } else {
        cart[index] = {
          productId: cart[index].productId,
          count: 1 + cart[index].count,
        };
      }
      await this.db.firestore
        .collection("users")
        .doc(uid)
        .set(
          {
            cart: cart,
          },
          { merge: true }
        )
        .then((res) => {
          this.ngOnInit();
          this.openSnackBar("Product added to your cart", "✅", 10000);
        });
    }
  }
}

@Component({
  selector: "address-dialog",
  templateUrl: "address-dialog.html",
  styleUrls: ["./cart.component.scss"],
})
export class AddressDialog {
  submitted: boolean = false;
  individualForm: FormGroup;
  corporateForm: FormGroup;
  billingAddress: any;

  @ViewChild("fform") individualFormDirective;
  @ViewChild("fform") corporateFormDirective;

  formErrors = {
    name: "",
    lastname: "",
    phone: "",
    city: "",
    country: "",
    address: "",
    tc: "",
    TIN: "",
    taxAdministration: "",
    companyName: "",
    addressTitle: "",
  };

  validationMessages = {
    name: {
      required: "يرجى إدخال الاسم",
    },
    lastname: {
      required: "يرجى إدخال الكنية",
    },
    phone: {
      required: "يرجى إدخال رقم الهاتف",
      pattern: "يجب أن يحتوي رقم الهاتف على أرقام فقط.",
    },
    city: {
      required: "يرجى إدخال المدينة",
    },
    country: {
      required: "يرجى إدخال البلد",
    },
    address: {
      required: "يرجى إدخال العنوان",
    },
    tc: {
      required: "يرجى إدخال رقم الوطني",
    },
    TIN: {
      required: "يرجى إدخال رقم الضريبة",
    },
    taxAdministration: {
      required: "يرجى إدخال إدارة الضرائب",
    },
    companyName: {
      required: "يرجى إدخال اسم الشركة",
    },
    addressTitle: {
      required: "يرجى إدخال لقب العنوان",
    },
  };

  constructor(
    public dialogRef: MatDialogRef<AddressDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private fb: FormBuilder,
    public db: AngularFirestore,
    public snackBar: MatSnackBar
  ) {
    this.createForm();
    this.createForm2();
  }

  createForm() {
    this.individualForm = this.fb.group({
      name: ["", [Validators.required]],
      lastname: ["", [Validators.required]],
      tc: [null],
      phone: ["", [Validators.required, Validators.pattern]],
      city: ["", [Validators.required]],
      country: ["", [Validators.required]],
      address: ["", [Validators.required]],
      addressTitle: ["", [Validators.required]],
    });

    this.individualForm.valueChanges.subscribe((data) => this.onValueChanged(data));

    this.onValueChanged(); //(re)set form validation messages
  }

  createForm2() {
    this.corporateForm = this.fb.group({
      name: ["", [Validators.required]],
      lastname: ["", [Validators.required]],
      TIN: ["", [Validators.required]],
      taxAdministration: ["", [Validators.required]],
      companyName: ["", [Validators.required]],
      phone: ["", [Validators.required, Validators.pattern]],
      city: ["", [Validators.required]],
      country: ["", [Validators.required]],
      address: ["", [Validators.required]],
      addressTitle: ["", [Validators.required]],
    });

    this.corporateForm.valueChanges.subscribe((data) => this.onValueChanged(data));

    this.onValueChanged(); //(re)set form validation messages
  }

  onValueChanged(data?: any) {
    if (!this.individualForm) {
      return;
    }
    const form = this.individualForm;
    for (const field in this.formErrors) {
      if (this.formErrors.hasOwnProperty(field)) {
        // clear previous erroe message (if any)
        this.formErrors[field] = "";
        const control = form.get(field);
        if (control && control.dirty && !control.valid) {
          const messages = this.validationMessages[field];
          for (const key in control.errors) {
            if (control.errors.hasOwnProperty(key)) {
              this.formErrors[field] += messages[key] + " ";
            }
          }
        }
      }
    }
  }

  onValueChanged2(data?: any) {
    if (!this.corporateForm) {
      return;
    }
    const form = this.corporateForm;
    for (const field in this.formErrors) {
      if (this.formErrors.hasOwnProperty(field)) {
        // clear previous erroe message (if any)
        this.formErrors[field] = "";
        const control = form.get(field);
        if (control && control.dirty && !control.valid) {
          const messages = this.validationMessages[field];
          for (const key in control.errors) {
            if (control.errors.hasOwnProperty(key)) {
              this.formErrors[field] += messages[key] + " ";
            }
          }
        }
      }
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  saveIndividualForm() {
    const formData = {
      ...this.individualForm.value,
      billingType: "individual",
    };
    this.billingAddress = formData;
    this.openSnackBar(" تم إضافة عنوان الفاتورة بنجاح ", "✅");
    this.dialogRef.close(this.billingAddress);
  }

  saveCorporateForm() {
    const formData = {
      ...this.corporateForm.value,
      billingType: "corporate",
    };
    this.billingAddress = formData;
    this.openSnackBar(" تم إضافة عنوان الفاتورة بنجاح ", "✅");
    this.dialogRef.close(this.billingAddress);
  }

  openSnackBar(message, action) {
    this.snackBar.open(message, action, {
      duration: 3000,
      horizontalPosition: "right",
      direction: "rtl",
    });
  }
}

@Component({
  selector: "agreement-dialog",
  templateUrl: "agreement-dialog.html",
  styleUrls: ["./cart.component.scss"],
})
export class AgreementDialog {
  preliminaryInformationForm: any;
  distanceSalesAgreement: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: privacyData) {
    this.preliminaryInformationForm = data.preliminaryInformationForm;
    this.distanceSalesAgreement = data.distanceSalesAgreement;
  }
}
