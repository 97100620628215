<app-header></app-header>
<main class="main">
  <div class="container" *ngIf="category">
    <div class="row">
      <div class="col-md-12">
        <img src="{{ horizontal }}" class="center swiper-imgae mb-4" width="100%" height="300px" />
      </div>
      <div class="col-md-12">
        <div class="card card-body" style="background-color: #fff">
          <div class="row" *ngIf="products.length > 0">
            <div class="col-lg-3 col-md-4 col-6 mb-4" *ngFor="let product of products; let i = index">
              <div class="card rounded-8px shadow-sm border-0" matBadge="جديد" matBadgePosition="after" [matBadgeHidden]="!product.new">
                <a [routerLink]="['/product', product.id]">
                  <img src="{{ product.images[0] }}" class="img-product" />
                </a>
                <div class="out-of-stock arabic" *ngIf="product.stock == 0 || product.stock == null || !product.stock">غير متوفر</div>
                <div class="card-body text-right" style="padding: 0.75rem">
                  <div style="height: 55px; overflow: hidden; text-overflow: ellipsis">
                    <a [routerLink]="['/product', product.id]" class="text-green arabic" style="font-size: 12px">
                      {{ product.nameAR }}
                    </a>
                  </div>
                  <div class="row" style="align-items: center">
                    <div style="text-align: inherit" class="col-5">
                      <h5
                        class="act-price"
                        style="text-align: inherit; color: #333333; font-size: 15px; margin-bottom: 0px !important"
                        *ngIf="product.discountType == 'noDiscount'">
                        {{ product.price }} TRY
                      </h5>
                      <h5
                        class="act-price"
                        style="text-align: inherit; color: #333333; font-size: 12px; margin-bottom: 0px !important"
                        *ngIf="product.discountType == 'discount'">
                        {{ product.price - product.discount }} TRY
                        <small class="dis-price">
                          {{ product.price }}
                          TRY</small
                        >
                      </h5>
                      <h5
                        class="act-price"
                        style="text-align: inherit; color: #333333; font-size: 15px; margin-bottom: 0px !important"
                        *ngIf="product.discountType == 'percent'">
                        {{ product.price - product.price * (product.discount / 100) }}
                        TRY
                        <small class="dis-price"> {{ product.price }} TRY</small>
                      </h5>
                    </div>

                    <div style="text-align: end; max-width: 52%" class="col-7">
                      <a
                        (click)="addingBestProducts(i)"
                        style="text-align: right; background: green; color: white; border: green"
                        *ngIf="product.stock > 0 && !product.addingCart"
                        class="btn btn-halloum2 btn-sm poppins"
                        >إضافة +</a
                      >
                      <div class="mt-1" *ngIf="product.addingCart">
                        <div class="stepper-input stepper__input">
                          <button class="toggle-less" (click)="toggleLessBestProducts(i)">
                            <i class="fas fa-minus"></i>
                          </button>
                          <div class="stepper-input-field" name="range-value" aria-atomic="true" aria-live="polite" aria-relevant="all">
                            {{ product.counter }}
                          </div>
                          <button class="toggle-more" (click)="toggleMoreBestProducts(i)" *ngIf="product.stock > product.counter">
                            <i class="fas fa-plus"></i>
                          </button>
                          <button class="toggle-more" *ngIf="product.stock <= product.counter" [disabled]="true">
                            <i class="fas fa-plus"></i>
                          </button>
                        </div>
                      </div>
                      <a
                        *ngIf="product.stock == 0 || product.stock == null || !product.stock"
                        class="btn btn-halloum2 btn-block btn-sm poppins disabled"
                        >غير متوفر</a
                      >
                    </div>
                  </div>
                  <div class="row" *ngIf="products.length == 0">
                    <div class="col-md-12">
                      <h3 class="text-center arabic">لا توجد منتجات مضافة بعد!</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <link
    rel="stylesheet"
    href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css"
    integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T"
    crossorigin="anonymous" />
  <link rel="stylesheet" href="https://unpkg.com/placeholder-loading/dist/css/placeholder-loading.min.css" />
  <div class="container" *ngIf="spinner">
    <div class="ph-item">
      <div class="ph-col-12">
        <div style="height: 270px" class="ph-picture"></div>
      </div>
    </div>
    <div class="ph-item">
      <div class="ph-col-3">
        <div style="height: 225px; width: 225" class="ph-picture"></div>
        <div class="ph-row">
          <div class="ph-col-6 big"></div>
          <div class="ph-col-4 empty big"></div>
          <div class="ph-col-2 big"></div>
          <div class="ph-col-4"></div>
          <div class="ph-col-8 empty"></div>
          <div class="ph-col-6"></div>
          <div class="ph-col-6 empty"></div>
          <div class="ph-col-12"></div>
        </div>
      </div>
      <div class="ph-col-3">
        <div style="height: 225px; width: 225" class="ph-picture"></div>
        <div class="ph-row">
          <div class="ph-col-6 big"></div>
          <div class="ph-col-4 empty big"></div>
          <div class="ph-col-2 big"></div>
          <div class="ph-col-4"></div>
          <div class="ph-col-8 empty"></div>
          <div class="ph-col-6"></div>
          <div class="ph-col-6 empty"></div>
          <div class="ph-col-12"></div>
        </div>
      </div>
      <div class="ph-col-3">
        <div style="height: 225px; width: 225" class="ph-picture"></div>
        <div class="ph-row">
          <div class="ph-col-6 big"></div>
          <div class="ph-col-4 empty big"></div>
          <div class="ph-col-2 big"></div>
          <div class="ph-col-4"></div>
          <div class="ph-col-8 empty"></div>
          <div class="ph-col-6"></div>
          <div class="ph-col-6 empty"></div>
          <div class="ph-col-12"></div>
        </div>
      </div>
      <div class="ph-col-3">
        <div style="height: 225px; width: 225" class="ph-picture"></div>
        <div class="ph-row">
          <div class="ph-col-6 big"></div>
          <div class="ph-col-4 empty big"></div>
          <div class="ph-col-2 big"></div>
          <div class="ph-col-4"></div>
          <div class="ph-col-8 empty"></div>
          <div class="ph-col-6"></div>
          <div class="ph-col-6 empty"></div>
          <div class="ph-col-12"></div>
        </div>
      </div>
    </div>
    <div class="ph-item">
      <div class="ph-col-3">
        <div style="height: 225px; width: 225" class="ph-picture"></div>
        <div class="ph-row">
          <div class="ph-col-6 big"></div>
          <div class="ph-col-4 empty big"></div>
          <div class="ph-col-2 big"></div>
          <div class="ph-col-4"></div>
          <div class="ph-col-8 empty"></div>
          <div class="ph-col-6"></div>
          <div class="ph-col-6 empty"></div>
          <div class="ph-col-12"></div>
        </div>
      </div>
      <div class="ph-col-3">
        <div style="height: 225px; width: 225" class="ph-picture"></div>
        <div class="ph-row">
          <div class="ph-col-6 big"></div>
          <div class="ph-col-4 empty big"></div>
          <div class="ph-col-2 big"></div>
          <div class="ph-col-4"></div>
          <div class="ph-col-8 empty"></div>
          <div class="ph-col-6"></div>
          <div class="ph-col-6 empty"></div>
          <div class="ph-col-12"></div>
        </div>
      </div>
      <div class="ph-col-3">
        <div style="height: 225px; width: 225" class="ph-picture"></div>
        <div class="ph-row">
          <div class="ph-col-6 big"></div>
          <div class="ph-col-4 empty big"></div>
          <div class="ph-col-2 big"></div>
          <div class="ph-col-4"></div>
          <div class="ph-col-8 empty"></div>
          <div class="ph-col-6"></div>
          <div class="ph-col-6 empty"></div>
          <div class="ph-col-12"></div>
        </div>
      </div>
      <div class="ph-col-3">
        <div style="height: 225px; width: 225" class="ph-picture"></div>
        <div class="ph-row">
          <div class="ph-col-6 big"></div>
          <div class="ph-col-4 empty big"></div>
          <div class="ph-col-2 big"></div>
          <div class="ph-col-4"></div>
          <div class="ph-col-8 empty"></div>
          <div class="ph-col-6"></div>
          <div class="ph-col-6 empty"></div>
          <div class="ph-col-12"></div>
        </div>
      </div>
    </div>
  </div>
</main>
<app-footer></app-footer>
