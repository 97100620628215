import { Component, OnInit, Inject, HostListener } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { Router } from "@angular/router";
import { SidenavService } from "../services/sidenav.service";
import { AngularFirestore } from "@angular/fire/firestore";
import { AuthService } from "../services/auth.service";
import { SessionStorageService } from "../services/session-storage.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  headerCategory: any[] = [];
  fixedNavbar: boolean = false;
  user: any;
  userData: any;
  public userInfo: any = undefined;
  loading: boolean = false;
  username: string = undefined;
  numOfCartProducts: number = 0;
  constructor(
    private sidenav: SidenavService,
    public authServices: AuthService,
    private router: Router,
    public db: AngularFirestore,
    private sessionStorageService: SessionStorageService,
    @Inject(DOCUMENT) private document: Document
  ) {}

  @HostListener("window:scroll", ["$event"]) onScrollEvent($event) {
    var currentScrollPos = window.pageYOffset;
    if (currentScrollPos > 150) {
      this.fixedNavbar = true;
    } else if (currentScrollPos <= 150) {
      this.fixedNavbar = false;
    }
  }

  ngOnInit(): void {
    this.db.firestore
      .collection("categories")
      .where("hidden", "==", false)
      .where("header", "==", true)
      .limit(5)
      .get()
      .then((documentSnapshots: any) => {
        for (var i in documentSnapshots.docs) {
          this.headerCategory[i] = documentSnapshots.docs[i].data();
          this.headerCategory[i].id = documentSnapshots.docs[i].id;
        }
      });
    if (JSON.parse(localStorage.getItem("user")) == null) {
      if (sessionStorage.getItem("cart")) {
        this.sessionStorageService.getItem("cart").subscribe((res: any) => {
          this.numOfCartProducts = res.length;
        });
      }
    } else {
      this.userInfo = JSON.parse(localStorage.getItem("user"));
      this.userData = JSON.parse(localStorage.getItem("userData"));
      this.db
        .collection("users")
        .doc(this.userInfo.uid)
        .snapshotChanges()
        .subscribe((res: any) => {
          this.numOfCartProducts = res.payload.data().cart?.length;
        });
    }
  }

  get sortData() {
    return this.headerCategory.sort((a, b) => {
      return a.headerSequence - b.headerSequence;
    });
  }

  signOut() {
    this.authServices.SignOut();
  }

  toggleSidenav() {
    this.sidenav.toggle();
  }

  goToEN(): void {
    var routerURL = this.router.url;
    this.document.location.href = "https://en.halloumoglu.com" + routerURL;
  }

  goToTR(): void {
    var routerURL = this.router.url;
    this.document.location.href = "https://tr.halloumoglu.com" + routerURL;
  }

  goToAR(): void {
    var routerURL = this.router.url;
    this.document.location.href = "https://ar.halloumoglu.com" + routerURL;
  }
}
