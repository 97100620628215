<app-header></app-header>
<main class="main">
  <div class="container-fluid">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-2">
          <div class="videowrapper center mb-4" *ngIf="video1">
            <iframe
              [src]="video1"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
          <div
            class="videowrapper center mb-4"
            *ngIf="video2"
            style="position: absolute; bottom: 0; left: 0"
          >
            <iframe
              [src]="video2"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </div>
        <div class="col-md-8">
          <div class="videowrapper center mb-4" *ngIf="video3">
            <iframe
              [src]="video3"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </div>
        <div class="col-md-2">
          <div class="videowrapper center mb-4" *ngIf="video4">
            <iframe
              [src]="video4"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
          <div
            class="videowrapper center mb-4"
            *ngIf="video5"
            style="position: absolute; bottom: 0; left: 0"
          >
            <iframe
              [src]="video5"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-1 center">
      <a
        class="btn-youtube youtube-zoom"
        style="font-size: 85px"
        [matTooltip]="'زوروا قناتنا على اليوتيوب'"
        matTooltipPosition="below"
        href="https://www.youtube.com/c/HALLOUMOGLU"
        target="_blank"
        ><i class="fab fa-youtube youtube"></i
      ></a>
    </div>
    <hr class="sep-3" />
    <div class="card card-body" style="background-color: #000; margin-top: 5%">
      <div class="gallery">
        <div
          class="gallery__column"
          *ngFor="let column of images; let i = index"
        >
          <a
            *ngFor="let image of column; let j = index"
            target="_blank"
            (click)="open(i, j)"
            class="gallery__link"
          >
            <figure class="gallery__thumb">
              <img [src]="image.src" class="gallery__image" />
              <figcaption class="gallery__caption">Halloumoğlu</figcaption>
            </figure>
          </a>
        </div>
      </div>
    </div>
    <div class="col-md-8 center mt-4">
      <app-follow-us></app-follow-us>
    </div>
  </div>
</main>
<app-footer></app-footer>
