<form class="form-class">
  <input
    class="input-class"
    type="text"
    style="width: 100%"
    placeholder="ابحث هنا"
    [formControl]="myControl"
    [matAutocomplete]="auto"
    (keyup)="onKeySearch($event)" />
  <mat-autocomplete #auto="matAutocomplete">
    <mat-option class="mat-option" *ngFor="let product of filteredProducts | async" [value]="product.name">
      <a routerLink="/product/{{ product.productId }}" class="poppins text-green">
        <div style="align-items: center; display: flex">
          <div>
            <img style="height: 50px; width: 50px; object-fit: contain" class="center" src="{{ product.image }}" />
          </div>
          <div>
            <span class="hit-name font-size">{{ product.nameAR }}</span> <br />
            <small class="hit-description">{{ product.categoryNameAR }}</small>
          </div>
        </div>
      </a>
    </mat-option>
  </mat-autocomplete>
</form>
