<div *ngIf="!mdScreen" class="hide-fab" id="fabButton" (scroll)="onScrollEvent($event)">
  <div style="margin-bottom: 50px" class="fab-container-wa">
    <a href="https://wa.me/+905300888034/?text=أبحث%20عن%20عن%20منتج%20معين%20أو%20خدمة%20,%20هل%20يمكنكم%20مساعدتي%20؟" target="_blank">
      <img
        style="width: 65%"
        class="fab-image"
        src="assets/used/whatsapp.png"
        alt=""
        [matTooltip]="'Send us a message'"
        matTooltipPosition="right" />
    </a>
  </div>
  <div style="padding-bottom: 40px" class="fab-container-arw">
    <a (click)="scrollToTop()" [matTooltip]="'اذهب للأعلى'" matTooltipPosition="above">
      <i class="fas fa-level-up-alt text-gold" style="font-size: 35px"></i>
    </a>
  </div>

  <div class="fab-container-setting">
    <mat-expansion-panel [expanded]="panelOpenState">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <a [matTooltip]="'اضبط دقة الصور'" matTooltipPosition="above">
            <i class="fas fa-cog fa-spin" style="font-size: 25px; color: #fff"></i>
          </a>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="sidenav" style="position: relative" dir="ltr">
        <div class="container-fluid">
          <div class="mt-4">
            <div class="row">
              <section>
                <mat-checkbox [checked]="HDimages" (click)="onClick()" class="example-margin" style="color: #fff" [color]="'primary'"
                  ><b>HD</b>
                </mat-checkbox>
              </section>
            </div>
          </div>
        </div>
      </div>
    </mat-expansion-panel>
  </div>
</div>
<div *ngIf="mdScreen"></div>

<!-- <div class="fb-customerchat" page_id="1760327654244006" logged_in_greeting="HALLOUMOĞLU AT YOUR SERVICE"
    logged_out_greeting="HALLOUMOĞLU AT YOUR SERVICE">
</div> -->
