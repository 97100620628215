import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { AngularFirestore } from "@angular/fire/firestore";

@Component({
  selector: "app-user-agreement",
  templateUrl: "./user-agreement.component.html",
  styleUrls: ["./user-agreement.component.scss"],
})
export class UserAgreementComponent implements OnInit {
  userAgreement: any;
  isMode: boolean = false;
  selectedPanel = 0;
  spinner: boolean = false;

  constructor(
    private router: Router,
    public db: AngularFirestore,
    private route: ActivatedRoute,
    private title: Title
  ) {
    this.title.setTitle("حلوم اوغلو - اتفاقية العضوية");
  }

  ngOnInit(): void {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });

    var first = this.db.firestore.collection("content").doc("user-agreementAR");
    first.get().then((documentSnapshots: any) => {
      this.userAgreement = documentSnapshots.data();
    });

    this.route.queryParams.subscribe((params) => {
      if (params["openPanel"]) {
        this.isMode = true;
        this.selectedPanel = parseInt(params["openPanel"]) - 1;
      } else {
        this.isMode = false;
      }
    });
    setTimeout(() => {
      this.spinner = true;
    }, 2000);
  }
}
