<main class="main">
  <div class="container">
    <div class="row">
      <div class="col-lg-8 col-md-12 mb-4">
        <div
          class="card card-body shadow"
          style="background-color: #fff; border: none"
        >
          <h3 class="h3-responsive arabic text-center">
            قم بتسجيل الدخول لبدء التسوق
          </h3>
          <h5
            *ngIf="verificationStatus == 'emailNotVerified'"
            class="poppins mt-4 text-center"
            style="color: #f93154"
          >
            لم يتم التحقق من حسابك ، لقد أرسلنا لك بالفعل رسالة التحقق من الحساب
            ، يرجى التحقق من بريدك الإلكتروني.
          </h5>
          <h5
            class="text-center arabic mt-4"
            *ngIf="errMess"
            style="color: red; font-weight: 600"
          >
            {{ errMess }}
          </h5>
          <form
            class="contact-form mt-4"
            novalidate
            [formGroup]="signInForm"
            #fform="ngForm"
          >
            <div class="row">
              <div class="col-md-12 mb-2">
                <mat-form-field
                  class="arabic"
                  appearance="outline"
                  color="primary"
                >
                  <mat-label>بريدك الإلكتروني</mat-label>
                  <input
                    matInput
                    formControlName="email"
                    #userName
                    type="email"
                    placeholder="بريدك الإلكتروني"
                    required
                  />
                  <mat-error *ngIf="formErrors.email">
                    {{ formErrors.email }}
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-12 mb-2">
                <mat-form-field
                  class="arabic"
                  appearance="outline"
                  color="primary"
                >
                  <mat-label>كلمة المرور</mat-label>
                  <input
                    matInput
                    formControlName="password"
                    #userPassword
                    type="password"
                    placeholder="كلمة المرور"
                    required
                  />
                  <mat-error *ngIf="formErrors.password">
                    {{ formErrors.password }}
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="col-md-12">
              <button
                [hidden]="loading"
                class="btn btn-primary btn-round btn-block btn-halloum mb-2 arabic"
                (click)="SignIn(userName.value, userPassword.value)"
                [disabled]="signInForm.invalid"
                mdbWavesEffect
                type="submit"
              >
                تسجيل الدخول
              </button>

              <button
                *ngIf="loading"
                class="btn btn-primary btn-round btn-block btn-halloum mb-2 poppins"
                [disabled]="true"
                mdbWavesEffect
                type="submit"
              >
                جاري تسجيل الدخول
                <i
                  style="margin-left: 3px"
                  class="fa fa-spinner fa-spin fa-lg"
                ></i>
              </button>

              <div class="row">
                <div class="col-md-6 col-12 arabic mb-2 text-right">
                  ليس لديك حساب؟
                  <a routerLink="/sign-up"> إنشاء حساب </a>
                </div>
                <div class="col-md-6 col-12 arabic forgot-password mb-2">
                  <a routerLink="/forgot-password" style="color: #005120d3">
                    هل نسيت كلمة المرور ؟
                  </a>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="col-lg-4 col-md-8 center">
        <div
          class="card card-body shadow"
          style="background-color: #fff; border: none"
        >
          <h3 class="h3-responsive arabic text-center">أو</h3>
          <hr />
          <a
            class="btn shadow mt-4 mb-4 arabic"
            style="background-color: #4285f4; color: #fff"
            (click)="authService.GoogleAuth(returnUrl)"
          >
            <i
              class="fab fa-google"
              style="margin-left: 10px; font-size: 19px"
            ></i>
            تسجيل دخول عن طريق غوغل
          </a>
          <a
            class="btn shadow mb-4 arabic"
            style="background-color: #3b5998; color: #fff"
            (click)="authService.FacebookAuth(returnUrl)"
          >
            <i
              class="fab fa-facebook-square"
              style="margin-left: 10px; font-size: 20px"
            ></i>
            تسجيل دخول عن طريق فيسبوك
          </a>
          <hr />
          <img src="assets/h.png" width="50%" class="center mt-4 mb-4" />
        </div>
      </div>
      <div class="col-md-12 center mt-4">
        <app-follow-us></app-follow-us>
      </div>
    </div>
  </div>
</main>
