import { Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AngularFirestore } from '@angular/fire/firestore';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/auth';
import { Title } from '@angular/platform-browser';

import { Location } from '@angular/common';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit {

  loading = false;
  errMess: string;
  verified: boolean;
  signInForm: FormGroup;
  verificationStatus: string = 'showForm';
  private routeSub: Subscription;

  returnUrl: string;

  @ViewChild('fform') quizFormDirective;

  formErrors = {
    'email': '',
    'password': '',
  };
  validationMessages = {
    'email': {
      'required': 'يرجى إدخال البريد الالكتروني',
      'email': 'صيغة البريد الالكتروني غير صحيحة'
    },
    'password': {
      'required': 'يرجى إدخال كلمة المرور',
    }
  };

  constructor(
    public authService: AuthService,
    public db: AngularFirestore,
    public afs: AngularFirestore,
    public afAuth: AngularFireAuth,
    public router: Router,
    private route: ActivatedRoute,
    public ngZone: NgZone,
    private fb: FormBuilder,
    private titleService: Title,
    private _location: Location
  ) {
    this.titleService.setTitle("حلوم اوغلو - تسجيل الدخول");
  }

  ngOnInit(): void {
    this.createForm();
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  createForm() {
    this.signInForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
    });

    this.signInForm.valueChanges
      .subscribe(data => this.onValueChanged(data));

    this.onValueChanged(); //(re)set form validation messages 
  }

  onValueChanged(data?: any) {
    if (!this.signInForm) {
      return;
    }
    const form = this.signInForm;
    for (const field in this.formErrors) {
      if (this.formErrors.hasOwnProperty(field)) {
        // clear previous erroe message (if any)
        this.formErrors[field] = '';
        const control = form.get(field);
        if (control && control.dirty && !control.valid) {
          const messages = this.validationMessages[field];
          for (const key in control.errors) {
            if (control.errors.hasOwnProperty(key)) {
              this.formErrors[field] += messages[key] + ' ';
            }
          }
        }
      }
    }
  }

  SignInService(email, password) {
    return this.afAuth.signInWithEmailAndPassword(email, password)
      .then((result) => {
        this.ngZone.run(() => {
          this.afAuth.authState.subscribe(user => {
            if (user) {
              this.router.navigateByUrl(this.returnUrl);
              this.afs.firestore.collection('users').doc(user.uid).get().then(async (userDoc: any) => {
                this.authService.sendUserData(userDoc.data())
                localStorage.setItem('user', JSON.stringify(user));
                localStorage.setItem('userData', JSON.stringify(userDoc.data()));
                if (sessionStorage.getItem('cart')) {
                  let storageCart = JSON.parse(sessionStorage.getItem('cart'));
                  let uid = user.uid
                  let cart = [];
                  await this.db.firestore.collection("users").doc(uid).get().then(res => {
                    if (res.data().cart) {
                      cart = res.data().cart;
                    }
                    for (let i in storageCart) {
                      const index = cart.findIndex(item => {
                        return item.productId === storageCart[i].productId;
                      })
                      if (index < 0) {
                        let product = {
                          productId: storageCart[i].productId,
                          count: storageCart[i].count
                        }
                        cart.push(product);
                      }
                      else {
                        cart[index] = {
                          productId: cart[index].productId,
                          count: storageCart[i].count + cart[index].count
                        }
                      }
                    }
                  });
                  await this.db.firestore.collection("users").doc(uid).set({
                    cart: cart
                  }, { merge: true }).then((res) => {
                    sessionStorage.removeItem('cart')
                  })
                }
              }).catch((error) => {
                this.authService.clearUserData();
                localStorage.setItem('user', null);
                localStorage.setItem('userData', null);
                this.authService.SignOutWithoutReloading();
              })
            } else {
              localStorage.setItem('user', null);
            }
          })
        });

      }).catch((error) => {
        throw error
      })
  }

  SignIn(email, password) {
    this.loading = true;
    this.SignInService(email, password).then(
      res => {
        this.loading = false;
      },
      error => {
        this.loading = false;
        if (error.code == "auth/wrong-password") {
          this.errMess = 'كلمة السر خاطئة';
        }
        else if (error.code == "auth/too-many-requests") {
          this.errMess = 'محاولات تسجيل دخول متعددة ، يرجى المحاولة مرة أخرى في وقت لاحق';
        }
        else if (error.code == "auth/user-not-found") {
          this.errMess = 'الحساب غير موجود';
        }
        else {
          this.errMess = error.message;
        }
      })
  }

  backClicked() {
    console.log(this._location.getState()["navigationId"]);
    ;
  }

}
