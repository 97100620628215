<div class="card card-body div-scroll text-right">
    <div class="pb-2">
        <a mat-list-item class="arabic text-green" routerLink="/profile">
            <p style="font-size: 13px;">
                <i class="fas fa-user-circle" style="margin-left: 10px;font-size: 15px;"></i>
                البروفايل
            </p>
        </a>
        <hr class="mt-3 mb-3 dropdown-divider">
    </div>
    <div class="pb-2">
        <a mat-list-item class="arabic text-green" routerLink="/cart">
            <p style="font-size: 13px;">
                <i class="fas fa-shopping-cart" style="margin-left: 10px;font-size: 15px;"></i>
                السلة
            </p>
        </a>
        <hr class="mt-3 mb-3 dropdown-divider">
    </div>
    <div class="pb-2">
        <a mat-list-item class="arabic text-green" routerLink="/my-orders">
            <p style="font-size: 13px;">
                <i class="fas fa-cubes" style="margin-left: 10px;font-size: 15px;"></i> طلباتي
            </p>
        </a>
        <hr class="mt-3 mb-3 dropdown-divider">
    </div>
    <div class="pb-2">
        <a mat-list-item class="arabic text-green" routerLink="/my-addresses">
            <p style="font-size: 13px;">
                <i class="fas fa-map-marker-alt" style="margin-left: 10px;font-size: 15px;"></i>
                عناويني
            </p>
        </a>
        <hr class="mt-3 mb-3 dropdown-divider">
    </div>
    <div class="pb-2">
        <a mat-list-item class="arabic text-green" (click)="signOut()">
            <p style="font-size: 13px;">
                <i class="fas fa-sign-out-alt" style="margin-left: 10px;font-size: 15px;"></i>
                تسجيل خروج
            </p>
        </a>
    </div>
</div>