<app-header></app-header>
<div *ngIf="!spinner">
  <main class="main">
    <div class="container">
      <div class="card card-body" style="background-color: #fff">
        <div class="row">
          <div
            class="col-md-6"
            style="border-right: 1px solid #dddddd"
            *ngFor="let branch of branches"
          >
            <div class="card card-body" style="border: none">
              <iframe
                [src]="branch.googleAR | safe"
                width="100%"
                height="400"
                style="border: 0; border-radius: 10px"
                allowfullscreen=""
                loading="lazy"
              ></iframe>
            </div>
            <div style="text-align: center">
              <h3 class="h3-responsive poppins text-center">
                {{ branch.nameAR }}
              </h3>
              Branch Address:
              <address class="poppins">
                <span [innerHTML]="branch.addressAR"></span>
              </address>
              <i class="fa fa-phone" style="margin-right: 10px"></i>
              <a
                href="tel:{{ branch.phoneLink }}"
                placement="top"
                mdbTooltip="Mr. {{ branch.telHolderName }}"
                >{{ branch.phone }}</a
              >
              <img
                style="height: 260px"
                src="{{ branch.image }}"
                class="center swiper-imgae mt-4"
                width="75%"
              />
            </div>
          </div>
        </div>
        <!-- <div class="col-md-8 center mt-4">
                    <app-follow-us></app-follow-us>
               </div> -->
      </div>
    </div>
  </main>
</div>
<div *ngIf="spinner">
  <div class="view w-100 ph-item">
    <div class="ph-col-12">
      <div style="height: 600px" class="ph-picture" width="100%"></div>
    </div>
  </div>
</div>

<app-footer></app-footer>
